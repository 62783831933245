import { IIconProps } from '../interface';

const SvgWallet = ({ width = 24, height = 24, color, type }: IIconProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        d="M20 14.5V17.5C20 18.163 19.7366 18.7989 19.2678 19.2678C18.7989 19.7366 18.163 20 17.5 20H5.5C4.83696 20 4.20107 19.7366 3.73223 19.2678C3.26339 18.7989 3 18.163 3 17.5V6.5C3 5.83696 3.26339 5.20107 3.73223 4.73223C4.20107 4.26339 4.83696 4 5.5 4H17.5C18.163 4 18.7989 4.26339 19.2678 4.73223C19.7366 5.20107 20 5.83696 20 6.5V9.5"
        stroke="#323232"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M18.5 9.5H20C20.2652 9.5 20.5196 9.60536 20.7071 9.79289C20.8946 9.98043 21 10.2348 21 10.5V13.5C21 13.7652 20.8946 14.0196 20.7071 14.2071C20.5196 14.3946 20.2652 14.5 20 14.5H18.5C17.837 14.5 17.2011 14.2366 16.7322 13.7678C16.2634 13.2989 16 12.663 16 12C16 11.337 16.2634 10.7011 16.7322 10.2322C17.2011 9.76339 17.837 9.5 18.5 9.5Z"
        stroke="#555962"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default SvgWallet;
