import { IIconProps } from '../interface';

const SvgMyIconsCityTownBuildingsSquare = ({
  width = 24,
  height = 24,
  color = '#323232',
}: IIconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g
        strokeLinecap="round"
        strokeWidth="1.5"
        stroke={color}
        fill="none"
        strokeLinejoin="round"
      >
        <path d="M15 12V8v0c0-.56-.45-1-1-1h-4l-.01 0c-.56 0-1 .44-1 1 0 0 0 0 0 0v2" />
        <path d="M14 17v-4l0 0c-.01-.56.44-1.01.99-1.01h2l-.01 0c.55-.01 1 .44 1 .99v3 -.001c0 .55-.45 1-1 1h-10l0 0c-.56-.01-1.01-.45-1.01-1v-3.52l-.001-.01c-.01-.47.26-.9.68-1.11l2.48-1.25 0-.01c.61-.31 1.35-.06 1.66.55 .08.16.12.35.13.54l.03 5.76" />
        <path d="M12 7l0-1.5" />
      </g>
      <rect
        width="18"
        height="18"
        x="3"
        y="3"
        rx="5"
        fill="none"
        stroke={color}
        strokeWidth="1.5"
      />
      <path fill="none" d="M0 0h24v24H0Z" />
    </svg>
  );
};

export default SvgMyIconsCityTownBuildingsSquare;
