import { IIconProps } from '../interface';

const SvgMyIconsEmployeeComputerLaptop = ({
  width = 24,
  height = 24,
  color = '#323232',
}: IIconProps) => {
  return (
    <svg
      width={width}
      height={height}
      id="Layer_3"
      data-name="Layer 3"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
    >
      <rect width="24" height="24" fill="none" />
      <path d="M24,0H0V24H24Z" fill="none" />
      <path
        d="M11.49978,20H5.12522A1.5,1.5,0,0,1,3.6368,18.68605l-.625-5A1.5,1.5,0,0,1,4.50022,12h6.37456a1.5,1.5,0,0,1,1.48842,1.314l.625,5A1.5,1.5,0,0,1,11.49978,20Z"
        fill="none"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
      />
      <line
        x1="17.70556"
        y1="20"
        x2="11.30083"
        y2="20"
        fill="none"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
      />
      <circle
        cx="14.5"
        cy="5.75"
        r="2.75"
        fill="none"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
      />
      <path
        d="M15,12h3a3,3,0,0,1,3,3v1a1,1,0,0,1-1,1H17"
        fill="none"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
      />
    </svg>
  );
};

export default SvgMyIconsEmployeeComputerLaptop;
