import { IIconProps } from '../interface';

const SvgBrazil = ({ width = 56, height = 56, type }: IIconProps) => {
  switch (type) {
    case 'circle':
      console.warn('Invalid option for this icon');
      return null;
    case 'md':
      console.warn('Invalid option for this icon');
      return null;
    case 'sm':
      console.warn('Invalid option for this icon');
      return null;
    case 'filled':
      console.warn('Invalid option for this icon');
      return null;
    case 'stripe':
      console.warn('Invalid option for this icon');
      return (
        <svg
          width={width}
          height={height}
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 16 16"
        >
          <g fill="none" fill-rule="evenodd" transform="translate(0 2)">
            <rect width="16" height="12" fill="#24B47E" rx="2"></rect>
            <path
              fill="#FCD669"
              fill-rule="nonzero"
              d="M7.999 1 15 6l-7.001 5L1 5.92z"
            ></path>
            <path
              fill="#43458B"
              fill-rule="nonzero"
              d="M8 3a3 3 0 1 1 0 6 3 3 0 0 1 0-6Z"
            ></path>
            <path
              fill="#FFF"
              fill-rule="nonzero"
              d="M10.848 6.945a2.99 2.99 0 0 1-.561.997c-1.28-1.4-3.17-2.297-5.238-2.485a2.99 2.99 0 0 1 .384-1.01c2.076.247 4.024 1.158 5.415 2.498Z"
            ></path>
          </g>
        </svg>
      );
    default:
      return (
        <svg
          width={width}
          height={height}
          viewBox="0 0 56 56"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M28 56C43.464 56 56 43.464 56 28C56 12.536 43.464 0 28 0C12.536 0 0 12.536 0 28C0 43.464 12.536 56 28 56Z"
            fill="#6DA544"
          />
          <path
            d="M28 10.9565L51.1304 28L28 45.0435L4.86963 28L28 10.9565Z"
            fill="#FFDA44"
          />
          <path
            d="M27.9999 37.7391C33.3787 37.7391 37.739 33.3787 37.739 28C37.739 22.6213 33.3787 18.2609 27.9999 18.2609C22.6212 18.2609 18.2609 22.6213 18.2609 28C18.2609 33.3787 22.6212 37.7391 27.9999 37.7391Z"
            fill="#F0F0F0"
          />
          <path
            d="M23.1305 27.3913C21.4369 27.3913 19.8025 27.6489 18.264 28.1269C18.3322 33.4469 22.6639 37.7392 28.0001 37.7392C31.2997 37.7392 34.2139 36.0967 35.9756 33.5864C32.9615 29.8133 28.3239 27.3913 23.1305 27.3913Z"
            fill="#0052B4"
          />
          <path
            d="M37.5586 29.8659C37.6759 29.2618 37.7392 28.6384 37.7392 28C37.7392 22.6212 33.3789 18.2609 28.0001 18.2609C23.9867 18.2609 20.5414 20.6892 19.0504 24.1561C20.3684 23.883 21.7329 23.7392 23.1306 23.7392C28.7872 23.7391 33.9044 26.0904 37.5586 29.8659Z"
            fill="#0052B4"
          />
        </svg>
      );
  }
};

export default SvgBrazil;
