import { ErrorBoundary } from 'react-error-boundary';
import AdviceScreen from './AdviceScreen';
import GenericError from '../../../components/genericError';

const Advice = () => {
  return (
    <ErrorBoundary
      fallback={<GenericError action={() => window.location.reload()} />}
    >
      <AdviceScreen />
    </ErrorBoundary>
  );
};

export default Advice;
