// src/redux/userReducer.ts
import { SET_USER_ID, CLEAR_USER_ID } from './../actionTypes';
import { UserActionTypes } from './actions';

export interface IStateUser {
  userId: string | null;
}

const initialState: IStateUser = {
  userId: null,
};

const userReducer = (state = initialState, action: UserActionTypes): IStateUser => {
  switch (action.type) {
    case SET_USER_ID:
      return {
        ...state,
        userId: action.payload,
      };
    case CLEAR_USER_ID:
      return {
        ...state,
        userId: null,
      };
    default:
      return state;
  }
};

export default userReducer;
