import { IIconProps } from '../interface';

const SvgMyIconsPhone = ({
  width = 24,
  height = 24,
  color = '#323232',
}: IIconProps) => {
  return (
    <svg
      width={width}
      height={height}
      id="Layer_3"
      data-name="Layer 3"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
    >
      <path
        d="M18.7021,18.96257l.85855-.85855a1.5,1.5,0,0,0,0-2.12132l-1.91734-1.91734a2,2,0,0,0-2.82843,0l-1.50309,1.50309a8.30632,8.30632,0,0,1-4.88024-4.88024l1.50307-1.5031a2,2,0,0,0,0-2.82841L8.0173,4.43936a1.5,1.5,0,0,0-2.12133,0l-.85855.85854A3.54193,3.54193,0,0,0,4,7.79936v0A12.19007,12.19007,0,0,0,16.2006,20h0A3.542,3.542,0,0,0,18.7021,18.96257Z"
        fill="none"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
      />
      <rect width="24" height="24" fill="none" />
    </svg>
  );
};

export default SvgMyIconsPhone;
