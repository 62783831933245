import { IIconProps } from '../interface';

const SvgIreland = ({ width = 56, height = 56, type }: IIconProps) => {
  if (type === 'stripe') {
    return (
      <svg
        width={width}
        height={height}
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 16 16"
      >
        <g fill="none">
          <path fill="#F6F9FC" d="M5 2h6v12H5z"></path>
          <path fill="#EAEEF3" d="M5 14v-1h6v1H5ZM5 2h6v1H5V2Z"></path>
          <path
            fill="#24B47E"
            d="M2 2h3v12H2a2 2 0 0 1-2-2V4a2 2 0 0 1 2-2Z"
          ></path>
          <path
            fill="#F79A59"
            d="M14 14h-3V2h3a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2Z"
          ></path>
        </g>
      </svg>
    );
  }

  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 56 56"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M28 56C43.464 56 56 43.464 56 28C56 12.536 43.464 0 28 0C12.536 0 0 12.536 0 28C0 43.464 12.536 56 28 56Z"
        fill="#F0F0F0"
      />
      <path
        d="M56 28C56 15.9611 48.4015 5.69779 37.7391 1.7417V54.2586C48.4015 50.3022 56 40.0391 56 28Z"
        fill="#FF9811"
      />
      <path
        d="M0 28C0 40.0391 7.5985 50.3022 18.2609 54.2585V1.7417C7.5985 5.69779 0 15.9611 0 28Z"
        fill="#6DA544"
      />
    </svg>
  );
};

export default SvgIreland;
