import { IIconProps } from '../interface';

const SvgRomania = ({ width = 56, height = 56, type }: IIconProps) => {
  switch (type) {
    case 'stripe':
      console.warn('Invalid option for this icon');
      return (
        <svg
          width={width}
          height={height}
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 16 16"
        >
          <g fill="none" fill-rule="evenodd">
            <path
              fill="#43458B"
              d="M2.005 2A2.008 2.008 0 0 0 0 4.009v7.982C0 13.101.894 14 2.005 14H5V2H2.005Z"
            ></path>
            <path
              fill="#E25950"
              d="M11 2v12h2.995A2.008 2.008 0 0 0 16 11.991V4.01C16 2.899 15.106 2 13.995 2H11Z"
            ></path>
            <path fill="#FCD669" d="M5 2h6v12H5z"></path>
            <path
              fill="#A68527"
              fill-opacity="0.1"
              fill-rule="nonzero"
              d="M5 14v-1h6v1H5ZM5 2h6v1H5V2Z"
            ></path>
          </g>
        </svg>
      );
    default:
      return (
        <svg
          width={width}
          height={height}
          viewBox="0 0 56 56"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g clipPath="url(#clip0_172_16456)">
            <path
              d="M37.7391 1.74147C34.7057 0.616109 31.425 1.45575e-08 28 1.45575e-08C24.5751 -0.00010936 21.2943 0.616109 18.261 1.74147L15.8262 27.9999L18.261 54.2583C21.2942 55.384 24.575 56 28 56C31.4249 56 34.7058 55.384 37.7391 54.2583L40.1738 28L37.7391 1.74147Z"
              fill="#FFDA44"
            />
            <path
              d="M56.0001 28C56.0001 15.9611 48.4016 5.69767 37.7391 1.74158V54.2586C48.4016 50.3021 55.9999 40.0391 56.0001 28Z"
              fill="#D80027"
            />
            <path
              d="M0 27.9999C0 40.0391 7.5985 50.3021 18.2607 54.2585L18.2608 1.74168C7.5985 5.69778 0 15.9609 0 27.9999H0Z"
              fill="#0052B4"
            />
          </g>
          <defs>
            <clipPath id="clip0_172_16456">
              <rect width="56" height="56" fill="white" />
            </clipPath>
          </defs>
        </svg>
      );
  }
};

export default SvgRomania;
