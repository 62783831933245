import { PropsWithChildren } from 'react';

const SkeletonBox = ({ children }: PropsWithChildren<unknown>) => {
  return (
    <div
      style={{
        marginBottom: '24px',
        borderRadius: '12px',
      }}
    >
      {children}
    </div>
  );
};

export default SkeletonBox;
