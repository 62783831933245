import { IIconProps } from '../interface';
const SvgPortugal = ({ width = 56, height = 56, type }: IIconProps) => {
  switch (type) {
    case 'circle':
      console.warn('Invalid option for this icon');
      return null;
    case 'md':
      console.warn('Invalid option for this icon');
      return null;
    case 'sm':
      console.warn('Invalid option for this icon');
      return null;
    case 'filled':
      console.warn('Invalid option for this icon');
      return null;
    case 'stripe':
      console.warn('Invalid option for this icon');
      return (
        <svg
          width={width}
          height={height}
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 16 16"
        >
          <g fill="none">
            <path
              fill="#24B47E"
              d="M6 14H2a2 2 0 0 1-2-2V4a2 2 0 0 1 2-2h4v12Z"
            ></path>
            <path
              fill="#E25950"
              d="M14 14H6V2h8a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2Z"
            ></path>
            <path
              fill="#FCD669"
              d="M5.975 5C7.645 5 9 6.343 9 7.999c0 1.656-1.355 2.999-3.025 2.999C4.324 10.97 3 9.636 3 7.999c0-1.636 1.324-2.97 2.975-2.998V5Z"
            ></path>
            <path
              fill="#E25950"
              d="M5.94 6a2 2 0 1 1 .12 4 2 2 0 0 1-.12-4Z"
            ></path>
            <path fill="#FFF" d="M5 7h2v1.5a1 1 0 1 1-2 0V7Z"></path>
          </g>
        </svg>
      );
    default:
      return (
        <svg
          width={width}
          height={height}
          viewBox="0 0 56 56"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M0 28C0 40.0389 7.5985 50.3021 18.2607 54.2583L20.6956 27.9999L18.2607 1.74158C7.5985 5.69789 0 15.961 0 28Z"
            fill="#6DA544"
          />
          <path
            d="M56 28C56 12.5361 43.4639 0 28 0C24.5751 0 21.2941 0.616109 18.2607 1.74158V54.2584C21.2941 55.384 24.5751 56 28 56C43.4639 56 56 43.4639 56 28Z"
            fill="#D80027"
          />
          <path
            d="M18.2608 37.7391C23.6396 37.7391 27.9999 33.3787 27.9999 28C27.9999 22.6213 23.6396 18.2609 18.2608 18.2609C12.8821 18.2609 8.52173 22.6213 8.52173 28C8.52173 33.3787 12.8821 37.7391 18.2608 37.7391Z"
            fill="#FFDA44"
          />
          <path
            d="M12.7827 23.1304V29.2173C12.7827 32.2429 15.2353 34.6956 18.261 34.6956C21.2866 34.6956 23.7392 32.243 23.7392 29.2173V23.1304H12.7827Z"
            fill="#D80027"
          />
          <path
            d="M18.2609 31.0435C17.254 31.0435 16.4348 30.2243 16.4348 29.2173V26.7827H20.087V29.2175C20.087 30.2243 19.2677 31.0435 18.2609 31.0435Z"
            fill="#F0F0F0"
          />
        </svg>
      );
  }
};

export default SvgPortugal;
