import { IIconProps } from '../interface';

const SvgMyIconsCompuerEmployee = ({
  width = 24,
  height = 24,
  color = '#323232',
}: IIconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g fill="none">
        <path d="M0 0h24v24H0Z" />
        <g
          strokeLinecap="round"
          strokeWidth="1.5"
          stroke={color}
          fill="none"
          strokeLinejoin="round"
        >
          <rect width="18" height="2.918" x="3" y="14.54" rx="1" />
          <path d="M4 17.5L4 21" />
          <path d="M10.87 11H3.11l-.01-.001c-.63-.01-1.13-.51-1.13-1.13v-5.76l0-.01c0-.63.5-1.13 1.12-1.13h7.75l0 0c.62 0 1.12.5 1.12 1.12v0 5.75 -.001c0 .62-.51 1.12-1.13 1.12Z" />
          <path d="M9.09 14.54L8.5 11" />
          <path d="M4.91 14.54L5.5 11" />
          <path d="M20 21l0-3.5" />
          <path d="M15.5 9.906h2.9l0 0c1.15 0 2.09.93 2.09 2.09" />
          <path d="M16.996 7.4l-.01-.001c-1.11-.01-2-.9-2-2.01 0-1.11.89-2 2-2 1.1 0 1.99.89 1.99 2 -.01 1.1-.9 1.99-2 1.99Z" />
        </g>
      </g>
    </svg>
  );
};

export default SvgMyIconsCompuerEmployee;
