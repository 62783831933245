import { IIconProps } from '../interface';

const SvgUnitedStates = ({ width = 56, height = 56, type }: IIconProps) => {
  switch (type) {
    case 'circle':
      console.warn('Invalid option for this icon');
      return null;
    case 'md':
      console.warn('Invalid option for this icon');
      return null;
    case 'sm':
      console.warn('Invalid option for this icon');
      return null;
    case 'filled':
      console.warn('Invalid option for this icon');
      return null;
    case 'stripe':
      console.warn('Invalid option for this icon');
      return (
        <svg
          width={width}
          height={height}
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 16 16"
        >
          <g fill="none" fill-rule="evenodd">
            <path
              fill="#E25950"
              fill-rule="nonzero"
              d="M14 14H2a2 2 0 0 1-2-2V4a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2Z"
            ></path>
            <path
              fill="#F6F9FC"
              fill-rule="nonzero"
              d="M0 11h16v1H0v-1Zm14 3H2a2 2 0 0 1-1.732-1h15.464A2 2 0 0 1 14 14ZM0 9h16v1H0V9Zm8-2h8v1H8V7Zm0-2h8v1H8V5Zm0-2h7.723c.171.295.277.634.277 1H8V3Z"
            ></path>
            <path
              fill="#E25950"
              fill-rule="nonzero"
              d="M14 14H2a2 2 0 0 1-2-2V8h1v4a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V4a1 1 0 0 0-1-1H8V2h6a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2Z"
              opacity="0.1"
            ></path>
            <path
              fill="#43458B"
              fill-rule="nonzero"
              d="M0 8V3.714C0 2.768.796 2 1.778 2H8v6H0Z"
            ></path>
            <path
              fill="#FFF"
              fill-rule="nonzero"
              d="M7.026 5.501a.5.5 0 1 1-1 0 .5.5 0 0 1 1 0ZM5.518 5a.5.5 0 1 1-.038-1 .5.5 0 0 1 .038 1Zm-.492.501a.5.5 0 1 1-1 0 .5.5 0 0 1 1 0ZM3.518 5a.5.5 0 1 1-.038-1 .5.5 0 0 1 .038 1Zm-.492.501a.5.5 0 1 1-1 0 .5.5 0 0 1 1 0Zm4-2a.5.5 0 1 1-1 0 .5.5 0 0 1 1 0Zm-2 0a.5.5 0 1 1-1 0 .5.5 0 0 1 1 0Zm-2 0a.5.5 0 1 1-1 0 .5.5 0 0 1 1 0Zm-1.507 1.5a.5.5 0 1 1-.038-.999.5.5 0 0 1 .038 1Zm.014 1A.5.5 0 1 1 1.57 7a.5.5 0 0 1-.037-1Zm2 0A.5.5 0 1 1 3.57 7a.5.5 0 0 1-.037-1Zm2 0A.5.5 0 1 1 5.57 7a.5.5 0 0 1-.037-1Z"
            ></path>
            <path
              fill="#FFF"
              d="M6.533 7a.5.5 0 1 1 .037 1 .5.5 0 0 1-.037-1Zm-2 0a.5.5 0 1 1 .037 1 .5.5 0 0 1-.037-1Zm-2 0a.5.5 0 1 1 .037 1 .5.5 0 0 1-.037-1ZM.482 7a.5.5 0 1 1 .036 1 .5.5 0 0 1-.036-1Zm0-2a.5.5 0 1 1 .036 1 .5.5 0 0 1-.036-1Zm0-2a.5.5 0 1 1 .036 1 .5.5 0 0 1-.036-1Zm1-1a.5.5 0 1 1 .036 1 .5.5 0 0 1-.036-1Zm2 0a.5.5 0 1 1 .036 1 .5.5 0 0 1-.036-1Zm2 0a.5.5 0 1 1 .036 1 .5.5 0 0 1-.036-1Zm2 0a.5.5 0 1 1 .036 1 .5.5 0 0 1-.036-1Zm0 2a.5.5 0 1 1 .036 1 .5.5 0 0 1-.036-1Zm0 2a.5.5 0 1 1 .036 1 .5.5 0 0 1-.036-1Z"
              opacity="0.5"
            ></path>
          </g>
        </svg>
      );
    default:
      return (
        <svg
          width={width}
          height={height}
          viewBox="0 0 56 57"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g clipPath="url(#clip0_172_16945)">
            <path
              d="M28 56.8359C43.464 56.8359 56 44.2999 56 28.8359C56 13.372 43.464 0.835938 28 0.835938C12.536 0.835938 0 13.372 0 28.8359C0 44.2999 12.536 56.8359 28 56.8359Z"
              fill="#F0F0F0"
            />
            <path
              d="M26.7827 28.8359H56.0001C56.0001 26.3087 55.6632 23.8604 55.0355 21.5315H26.7827V28.8359Z"
              fill="#D80027"
            />
            <path
              d="M26.7827 14.2272H51.8904C50.1764 11.4303 47.9848 8.9581 45.4296 6.92285H26.7827V14.2272Z"
              fill="#D80027"
            />
            <path
              d="M28 56.8359C34.5897 56.8359 40.6466 54.5583 45.4296 50.749H10.5704C15.3534 54.5583 21.4103 56.8359 28 56.8359Z"
              fill="#D80027"
            />
            <path
              d="M4.10968 43.4446H51.8904C53.2664 41.1992 54.3336 38.7449 55.0354 36.1402H0.9646C1.66646 38.7449 2.73363 41.1992 4.10968 43.4446Z"
              fill="#D80027"
            />
            <path
              d="M12.9701 5.20853H15.5217L13.1483 6.93283L14.0549 9.72287L11.6816 7.99858L9.30825 9.72287L10.0914 7.31258C8.00166 9.05328 6.17006 11.0927 4.66069 13.3651H5.47827L3.96747 14.4626C3.73209 14.8553 3.50634 15.2542 3.29 15.659L4.01144 17.8794L2.66547 16.9015C2.33089 17.6103 2.02486 18.3352 1.74978 19.0751L2.54461 21.5216H5.47827L3.10483 23.2459L4.01144 26.0359L1.63811 24.3116L0.216453 25.3446C0.0741562 26.4884 0 27.6535 0 28.8359H28C28 13.3721 28 11.549 28 0.835938C22.4687 0.835938 17.3124 2.44047 12.9701 5.20853ZM14.0549 26.0359L11.6816 24.3116L9.30825 26.0359L10.2149 23.2459L7.84142 21.5216H10.7751L11.6816 18.7315L12.5881 21.5216H15.5217L13.1483 23.2459L14.0549 26.0359ZM13.1483 15.0894L14.0549 17.8794L11.6816 16.1551L9.30825 17.8794L10.2149 15.0894L7.84142 13.3651H10.7751L11.6816 10.575L12.5881 13.3651H15.5217L13.1483 15.0894ZM24.0984 26.0359L21.725 24.3116L19.3517 26.0359L20.2583 23.2459L17.8849 21.5216H20.8185L21.725 18.7315L22.6315 21.5216H25.5652L23.1918 23.2459L24.0984 26.0359ZM23.1918 15.0894L24.0984 17.8794L21.725 16.1551L19.3517 17.8794L20.2583 15.0894L17.8849 13.3651H20.8185L21.725 10.575L22.6315 13.3651H25.5652L23.1918 15.0894ZM23.1918 6.93283L24.0984 9.72287L21.725 7.99858L19.3517 9.72287L20.2583 6.93283L17.8849 5.20853H20.8185L21.725 2.41848L22.6315 5.20853H25.5652L23.1918 6.93283Z"
              fill="#0052B4"
            />
          </g>
        </svg>
      );
  }
};

export default SvgUnitedStates;
