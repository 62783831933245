import { IIconProps } from '../interface';

const SvgNorway = ({ width = 56, height = 56, type }: IIconProps) => {
  switch (type) {
    case 'stripe':
      console.warn('Invalid option for this icon');
      return (
        <svg
          width={width}
          height={height}
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 16 16"
        >
          <g fill="none">
            <path
              fill="#E25950"
              d="M2 2h12a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V4a2 2 0 0 1 2-2Z"
            ></path>
            <path fill="#F6F9FC" d="M16 9H7v5H4V9H0V6h4V2h3v4h9z"></path>
            <path fill="#43458B" d="M16 8H6v6H5V8H0V7h5V2h1v5h10z"></path>
            <path
              fill="#555ABF"
              d="M15 9V6h1v3h-1ZM4 13h3v1H4v-1ZM4 2h3v1H4V2ZM0 6h1v3H0V6Z"
              opacity="0.1"
            ></path>
          </g>
        </svg>
      );
    default:
      return (
        <svg
          width={width}
          height={height}
          viewBox="0 0 56 56"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g clipPath="url(#clip0_172_16323)">
            <path
              d="M28 56C43.464 56 56 43.464 56 28C56 12.536 43.464 0 28 0C12.536 0 0 12.536 0 28C0 43.464 12.536 56 28 56Z"
              fill="#F0F0F0"
            />
            <path
              d="M0.9646 35.3044C2.58466 41.3155 6.15521 46.5255 10.9566 50.2146V35.3044H0.9646Z"
              fill="#D80027"
            />
            <path
              d="M25.5652 55.8935C26.3678 55.9626 27.1795 56 28 56C40.9367 56 51.8224 47.2258 55.0354 35.3044H25.5652V55.8935Z"
              fill="#D80027"
            />
            <path
              d="M55.0354 20.6956C51.8224 8.77417 40.9367 0 28 0C27.1795 0 26.3678 0.0374062 25.5652 0.106531V20.6956H55.0354Z"
              fill="#D80027"
            />
            <path
              d="M10.9566 5.78539C6.15521 9.47461 2.58466 14.6845 0.9646 20.6956H10.9566V5.78539Z"
              fill="#D80027"
            />
            <path
              d="M55.763 24.3479H21.9132H21.9131V0.66555C19.3255 1.23933 16.8723 2.16935 14.6087 3.40452V24.3476V24.3478H0.237016C0.0811563 25.5433 0 26.7622 0 28C0 29.2378 0.0811563 30.4567 0.237016 31.6521H14.6086H14.6087V52.5954C16.8723 53.8304 19.3255 54.7607 21.9131 55.3344V31.6525V31.6523H55.763C55.9186 30.4567 56 29.2378 56 28C56 26.7622 55.9186 25.5433 55.763 24.3479Z"
              fill="#0052B4"
            />
          </g>
          <defs>
            <clipPath id="clip0_172_16323">
              <rect width="56" height="56" fill="white" />
            </clipPath>
          </defs>
        </svg>
      );
  }
};

export default SvgNorway;
