import { IIconProps } from '../interface';

const SvgSlovakia = ({ width = 56, height = 56, type }: IIconProps) => {
  switch (type) {
    case 'circle':
      console.warn('Invalid option for this icon');
      return null;
    case 'md':
      console.warn('Invalid option for this icon');
      return null;
    case 'sm':
      console.warn('Invalid option for this icon');
      return null;
    case 'filled':
      console.warn('Invalid option for this icon');
      return null;
    case 'stripe':
      console.warn('Invalid option for this icon');
      return (
        <svg
          width={width}
          height={height}
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 16 16"
        >
          <g fill="none" fill-rule="evenodd">
            <path
              fill="#F6F9FC"
              d="M1.994 2A1.997 1.997 0 0 0 0 4.005V7h16V4.005A2 2 0 0 0 14.006 2H1.994Z"
            ></path>
            <path
              fill="#EAEEF3"
              fill-rule="nonzero"
              d="M15 6V4.005A1 1 0 0 0 14.006 3H1.994A.997.997 0 0 0 1 4.005V6H0V4.005C0 2.894.893 2 1.994 2h12.012A2 2 0 0 1 16 4.005V6h-1Z"
            ></path>
            <path fill="#217AB7" d="M0 6h16v4H0z"></path>
            <path
              fill="#E25950"
              d="M0 10v2c0 1.105.895 2 1.994 2h12.012A1.992 1.992 0 0 0 16 12v-2H0Z"
            ></path>
            <path
              fill="#43458B"
              fill-rule="nonzero"
              d="M3 6h4v2.5a2 2 0 1 1-4 0V6Z"
            ></path>
            <path fill="#E25950" d="M3 6h4v3H3z"></path>
          </g>
        </svg>
      );
    default:
      return (
        <svg
          width={width}
          height={height}
          viewBox="0 0 56 56"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M28 56C43.464 56 56 43.464 56 28C56 12.536 43.464 0 28 0C12.536 0 0 12.536 0 28C0 43.464 12.536 56 28 56Z"
            fill="#F0F0F0"
          />
          <path
            d="M28 56C43.4639 56 56 43.4639 56 28C56 24.575 55.384 21.2941 54.2584 18.2609H1.74158C0.616109 21.2941 0 24.575 0 28C0 43.4639 12.5361 56 28 56Z"
            fill="#0052B4"
          />
          <path
            d="M28 56C40.039 56 50.3022 48.4015 54.2584 37.7391H1.74158C5.69778 48.4015 15.961 56 28 56Z"
            fill="#D80027"
          />
          <path
            d="M7.24036 15.8261V29.794C7.24036 37.7394 17.6202 40.174 17.6202 40.174C17.6202 40.174 27.9998 37.7394 27.9998 29.794V15.8261H7.24036Z"
            fill="#F0F0F0"
          />
          <path
            d="M9.67517 15.8261V29.794C9.67517 30.7267 9.88233 31.6076 10.2927 32.4335H24.9475C25.3579 31.6077 25.5651 30.7267 25.5651 29.794V15.8261H9.67517Z"
            fill="#D80027"
          />
          <path
            d="M22.4897 25.5652H18.8377V23.1304H21.2725V20.6956H18.8377V18.2609H16.4029V20.6956H13.9682V23.1304H16.4029V25.5652H12.7506V28H16.4029V30.4348H18.8377V28H22.4897V25.5652Z"
            fill="#F0F0F0"
          />
          <path
            d="M13.614 35.8324C15.183 36.8184 16.795 37.3908 17.6202 37.6455C18.4455 37.391 20.0574 36.8184 21.6264 35.8324C23.2086 34.8379 24.3178 33.7006 24.9476 32.4335C24.2529 31.9421 23.4054 31.6523 22.4898 31.6523C22.1565 31.6523 21.8326 31.6917 21.5212 31.7643C20.8613 30.2649 19.3635 29.2175 17.6203 29.2175C15.8772 29.2175 14.3792 30.2649 13.7195 31.7643C13.4081 31.6917 13.0841 31.6523 12.7509 31.6523C11.8353 31.6523 10.9877 31.9421 10.2931 32.4335C10.9225 33.7005 12.0317 34.8378 13.614 35.8324Z"
            fill="#0052B4"
          />
        </svg>
      );
  }
};

export default SvgSlovakia;
