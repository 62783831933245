import { IIconProps } from '../interface';

const SvgEstonia = ({ width = 56, height = 56, type }: IIconProps) => {
  if (type === 'stripe') {
    return (
      <svg
        width={width}
        height={height}
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 16 16"
      >
        <g fill="none" fill-rule="evenodd">
          <path
            fill="#45B2E8"
            d="M1.994 2A1.992 1.992 0 0 0 0 4v2h16V4c0-1.105-.895-2-1.994-2H1.994Z"
          ></path>
          <path
            fill="#F6F9FC"
            d="M0 10v2c0 1.105.895 2 1.994 2h12.012A1.992 1.992 0 0 0 16 12v-2H0Z"
          ></path>
          <path
            fill="#EAEEF3"
            fill-rule="nonzero"
            d="M14 14H2a2 2 0 0 1-2-2v-2h1v2a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2h1v2a2 2 0 0 1-2 2Z"
          ></path>
          <path fill="#333" d="M0 6h16v4H0z"></path>
        </g>
      </svg>
    );
  }

  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 56 56"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0 28C0 31.425 0.616109 34.7058 1.74158 37.7391L28 38.9565L54.2584 37.7392C55.384 34.7058 56 31.425 56 28C56 24.575 55.384 21.2942 54.2584 18.2609L28 17.0435L1.74158 18.2608C0.616109 21.2942 0 24.575 0 28H0Z"
        fill="black"
      />
      <path
        d="M28 0C15.961 0 5.69778 7.5985 1.74158 18.2609H54.2585C50.3022 7.5985 40.039 0 28 0Z"
        fill="#0052B4"
      />
      <path
        d="M54.2584 37.7391H1.74158C5.69778 48.4014 15.961 56 28 56C40.039 56 50.3022 48.4014 54.2584 37.7391Z"
        fill="#F0F0F0"
      />
    </svg>
  );
};

export default SvgEstonia;
