import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { initialCurrentQuestionState } from '../../../consts';
import { IconsListEnum } from '../../../design-system/Icon/icons/enum';
import { ANCHOR_VARIANTS } from '../../../design-system/Tool/Anchor';
import { defaultQuestions } from '../../../flows';
import categeriesFlows from '../../../flows/categeriesFlows';
import { IRootState } from '../../../store';

const useSidebar = () => {
  const state = useSelector((state: IRootState) => state.trt);

  const getNextQuestion = () => {
    const nextQuestion = state.roadmap.find((question) => !question.done);
    return nextQuestion || initialCurrentQuestionState;
  };

  const sidebar = useMemo(() => {
    const categories = state.roadmap.reduce((acc, crr) => {
      if (!acc.includes(crr.category_id)) {
        return [...acc, crr.category_id];
      }

      return [...acc];
    }, [] as string[]);

    return categories.map((category) => {
      const roadmapQuestions = state.roadmap.filter(
        (roadmap) => roadmap.category_id === category
      );

      const next = getNextQuestion();

      const questions = roadmapQuestions.map((question) => {
        return {
          id: question.id,
          label: defaultQuestions[question.id]?.label || question.id,
          action: () => {},
          icon: IconsListEnum.Home,
          done: question.done,
          variant: ANCHOR_VARIANTS.QUESTION,
          disabled: question.ignore
            ? true
            : question.done
            ? !question.done
            : next.id !== question.id,
        };
      });

      const { label, icon } = categeriesFlows[category] || {};

      const done = state.roadmap.filter(
        (roadmap) => roadmap.category_id === category && !roadmap.done
      );
      const ignored = questions.filter(
        (question) => question.disabled === false
      );

      return {
        id: category,
        label: label || `countries.${roadmapQuestions[0]?.initial}`,
        labelYear: roadmapQuestions[0]?.initial
          ? roadmapQuestions[0]?.fiscalYear || state.fiscalYear
          : undefined,
        action: () => {},
        icon: icon ? IconsListEnum[icon] : IconsListEnum.Globe,
        done: !done.length,
        variant: ANCHOR_VARIANTS.CATEGORY,
        disabled: !ignored.length
          ? true
          : !done.length
          ? false
          : next.category_id !== category,
        opened: next.category_id === category,
        questions,
      };
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.roadmap]);

  return {
    sidebar,
  };
};

export default useSidebar;
