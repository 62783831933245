import { t } from 'i18next';
import { Button, Typography } from '../../design-system';

interface IGenericError {
  title?: string;
  description?: string;
  actionText?: string;
  action?: () => void;
}

const GenericError = ({
  title,
  description,
  actionText,
  action,
}: IGenericError) => {
  return (
    <div className="flex gap-6 flex-col w-full h-screen items-center justify-center">
      <div className="flex flex-col justify-center text-center">
        <Typography variant={'BodyLarge500'}>
          {title || t('app.generic_error.title')}
        </Typography>
        <Typography variant={'BodyDefault400'}>
          {description || t('app.generic_error.description')}
        </Typography>
      </div>
      {action && (
        <Button size={'default'} variant={'primary'} onClick={() => action()}>
          {actionText || t('app.generic_error.action')}
        </Button>
      )}
    </div>
  );
};

export default GenericError;
