import env from '../env';

const fiscalYears: {
  [key: string]: {
    fyStartMonth: string;
    fyStartDay: string;
    fyEndMonth: string;
    fyEndDay: string;
  };
} = {
  ...(['development', 'staging'].includes(env.APP_ENV!)
    ? {
        AA: {
          fyStartMonth: '05',
          fyStartDay: '02',
          fyEndMonth: '05',
          fyEndDay: '01',
        },
      }
    : {}),
  AF: {
    fyStartMonth: '12',
    fyStartDay: '21',
    fyEndMonth: '12',
    fyEndDay: '20',
  },
  AI: {
    fyStartMonth: '04',
    fyStartDay: '1',
    fyEndMonth: '03',
    fyEndDay: '31',
  },
  AG: {
    fyStartMonth: '04',
    fyStartDay: '1',
    fyEndMonth: '03',
    fyEndDay: '31',
  },
  AU: {
    fyStartMonth: '07',
    fyStartDay: '1',
    fyEndMonth: '06',
    fyEndDay: '30',
  },
  BS: {
    fyStartMonth: '07',
    fyStartDay: '1',
    fyEndMonth: '06',
    fyEndDay: '30',
  },
  BD: {
    fyStartMonth: '07',
    fyStartDay: '1',
    fyEndMonth: '06',
    fyEndDay: '30',
  },
  BB: {
    fyStartMonth: '04',
    fyStartDay: '1',
    fyEndMonth: '03',
    fyEndDay: '31',
  },
  BZ: {
    fyStartMonth: '04',
    fyStartDay: '1',
    fyEndMonth: '03',
    fyEndDay: '31',
  },
  BM: {
    fyStartMonth: '04',
    fyStartDay: '1',
    fyEndMonth: '03',
    fyEndDay: '31',
  },
  BT: {
    fyStartMonth: '07',
    fyStartDay: '1',
    fyEndMonth: '06',
    fyEndDay: '30',
  },
  BW: {
    fyStartMonth: '04',
    fyStartDay: '1',
    fyEndMonth: '03',
    fyEndDay: '31',
  },
  VG: {
    fyStartMonth: '04',
    fyStartDay: '1',
    fyEndMonth: '03',
    fyEndDay: '31',
  },
  BN: {
    fyStartMonth: '04',
    fyStartDay: '1',
    fyEndMonth: '03',
    fyEndDay: '31',
  },
  MM: {
    fyStartMonth: '04',
    fyStartDay: '1',
    fyEndMonth: '03',
    fyEndDay: '31',
  },
  CM: {
    fyStartMonth: '07',
    fyStartDay: '1',
    fyEndMonth: '06',
    fyEndDay: '30',
  },
  CA: {
    fyStartMonth: '04',
    fyStartDay: '1',
    fyEndMonth: '03',
    fyEndDay: '31',
  },
  KY: {
    fyStartMonth: '04',
    fyStartDay: '1',
    fyEndMonth: '03',
    fyEndDay: '31',
  },
  CX: {
    fyStartMonth: '07',
    fyStartDay: '1',
    fyEndMonth: '06',
    fyEndDay: '30',
  },
  CC: {
    fyStartMonth: '07',
    fyStartDay: '1',
    fyEndMonth: '06',
    fyEndDay: '30',
  },
  CK: {
    fyStartMonth: '04',
    fyStartDay: '1',
    fyEndMonth: '03',
    fyEndDay: '31',
  },
  DM: {
    fyStartMonth: '07',
    fyStartDay: '1',
    fyEndMonth: '06',
    fyEndDay: '30',
  },
  EG: {
    fyStartMonth: '07',
    fyStartDay: '1',
    fyEndMonth: '06',
    fyEndDay: '30',
  },
  EW: {
    fyStartMonth: '07',
    fyStartDay: '1',
    fyEndMonth: '06',
    fyEndDay: '30',
  },
  ET: {
    fyStartMonth: '07',
    fyStartDay: '8',
    fyEndMonth: '07',
    fyEndDay: '7',
  },
  FK: {
    fyStartMonth: '04',
    fyStartDay: '1',
    fyEndMonth: '03',
    fyEndDay: '31',
  },
  GI: {
    fyStartMonth: '07',
    fyStartDay: '1',
    fyEndMonth: '06',
    fyEndDay: '30',
  },
  HT: {
    fyStartMonth: '10',
    fyStartDay: '1',
    fyEndMonth: '09',
    fyEndDay: '30',
  },
  HK: {
    fyStartMonth: '04',
    fyStartDay: '1',
    fyEndMonth: '03',
    fyEndDay: '31',
  },
  IN: {
    fyStartMonth: '04',
    fyStartDay: '1',
    fyEndMonth: '03',
    fyEndDay: '31',
  },
  IR: {
    fyStartMonth: '03',
    fyStartDay: '21',
    fyEndMonth: '03',
    fyEndDay: '20',
  },
  IM: {
    fyStartMonth: '04',
    fyStartDay: '1',
    fyEndMonth: '03',
    fyEndDay: '31',
  },
  JE: {
    fyStartMonth: '04',
    fyStartDay: '1',
    fyEndMonth: '03',
    fyEndDay: '31',
  },
  KE: {
    fyStartMonth: '07',
    fyStartDay: '1',
    fyEndMonth: '06',
    fyEndDay: '30',
  },
  KW: {
    fyStartMonth: '04',
    fyStartDay: '1',
    fyEndMonth: '03',
    fyEndDay: '31',
  },
  LS: {
    fyStartMonth: '04',
    fyStartDay: '1',
    fyEndMonth: '03',
    fyEndDay: '31',
  },
  MW: {
    fyStartMonth: '07',
    fyStartDay: '1',
    fyEndMonth: '06',
    fyEndDay: '30',
  },
  MH: {
    fyStartMonth: '10',
    fyStartDay: '1',
    fyEndMonth: '09',
    fyEndDay: '30',
  },
  MU: {
    fyStartMonth: '07',
    fyStartDay: '1',
    fyEndMonth: '06',
    fyEndDay: '30',
  },
  FM: {
    fyStartMonth: '10',
    fyStartDay: '1',
    fyEndMonth: '09',
    fyEndDay: '30',
  },
  MS: {
    fyStartMonth: '04',
    fyStartDay: '1',
    fyEndMonth: '03',
    fyEndDay: '31',
  },
  NA: {
    fyStartMonth: '04',
    fyStartDay: '1',
    fyEndMonth: '03',
    fyEndDay: '31',
  },
  NR: {
    fyStartMonth: '07',
    fyStartDay: '1',
    fyEndMonth: '06',
    fyEndDay: '30',
  },
  NP: {
    fyStartMonth: '07',
    fyStartDay: '16',
    fyEndMonth: '07',
    fyEndDay: '15',
  },
  NZ: {
    fyStartMonth: '04',
    fyStartDay: '1',
    fyEndMonth: '03',
    fyEndDay: '31',
  },
  NU: {
    fyStartMonth: '04',
    fyStartDay: '1',
    fyEndMonth: '03',
    fyEndDay: '31',
  },
  NF: {
    fyStartMonth: '07',
    fyStartDay: '1',
    fyEndMonth: '06',
    fyEndDay: '30',
  },
  MP: {
    fyStartMonth: '10',
    fyStartDay: '1',
    fyEndMonth: '09',
    fyEndDay: '30',
  },
  PK: {
    fyStartMonth: '07',
    fyStartDay: '1',
    fyEndMonth: '06',
    fyEndDay: '30',
  },
  PW: {
    fyStartMonth: '10',
    fyStartDay: '1',
    fyEndMonth: '09',
    fyEndDay: '30',
  },
  SH: {
    fyStartMonth: '04',
    fyStartDay: '1',
    fyEndMonth: '03',
    fyEndDay: '31',
  },
  WS: {
    fyStartMonth: '06',
    fyStartDay: '1',
    fyEndMonth: '05',
    fyEndDay: '31',
  },
  ZA: {
    fyStartMonth: '03',
    fyStartDay: '1',
    fyEndMonth: '02',
    fyEndDay: '28',
  },
  TK: {
    fyStartMonth: '07',
    fyStartDay: '1',
    fyEndMonth: '06',
    fyEndDay: '30',
  },
  TO: {
    fyStartMonth: '07',
    fyStartDay: '1',
    fyEndMonth: '06',
    fyEndDay: '30',
  },
  UG: {
    fyStartMonth: '07',
    fyStartDay: '1',
    fyEndMonth: '06',
    fyEndDay: '30',
  },
  GB: {
    fyStartMonth: '04',
    fyStartDay: '6',
    fyEndMonth: '04',
    fyEndDay: '5',
  },
};

export default fiscalYears;
