import { IIconProps } from '../interface';

const SvgFrance = ({ width = 56, height = 56, type }: IIconProps) => {
  switch (type) {
    case 'circle':
      console.warn('Invalid option for this icon');
      return null;
    case 'md':
      console.warn('Invalid option for this icon');
      return null;
    case 'sm':
      console.warn('Invalid option for this icon');
      return null;
    case 'filled':
      console.warn('Invalid option for this icon');
      return null;
    case 'stripe':
      console.warn('Invalid option for this icon');
      return (
        <svg
          width={width}
          height={height}
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 16 16"
        >
          <g fill="none" fill-rule="evenodd">
            <path
              fill="#43458B"
              d="M2.005 2A2.008 2.008 0 0 0 0 4.009v7.982C0 13.101.894 14 2.005 14H5V2H2.005Z"
            ></path>
            <path
              fill="#E25950"
              d="M11 2v12h2.995A2.008 2.008 0 0 0 16 11.991V4.01C16 2.899 15.106 2 13.995 2H11Z"
            ></path>
            <path fill="#F6F9FC" d="M5 2h6v12H5z"></path>
            <path
              fill="#EAEEF3"
              fill-rule="nonzero"
              d="M5 14v-1h6v1H5ZM5 2h6v1H5V2Z"
            ></path>
          </g>
        </svg>
      );
    default:
      return (
        <svg
          width={width}
          height={height}
          viewBox="0 0 56 56"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M28 56C43.464 56 56 43.464 56 28C56 12.536 43.464 0 28 0C12.536 0 0 12.536 0 28C0 43.464 12.536 56 28 56Z"
            fill="#F0F0F0"
          />
          <path
            d="M56.0001 28C56.0001 15.961 48.4016 5.69778 37.7391 1.74158V54.2585C48.4016 50.3022 56.0001 40.039 56.0001 28Z"
            fill="#D80027"
          />
          <path
            d="M0 28C0 40.039 7.59861 50.3022 18.2609 54.2584V1.74158C7.59861 5.69778 0 15.961 0 28Z"
            fill="#0052B4"
          />
        </svg>
      );
  }
};

export default SvgFrance;
