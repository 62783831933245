import { useEffect } from 'react';
import { Navigate, Outlet, useLocation } from 'react-router-dom';
import { supabase } from '../../supabaseClient';
import UserService from '../../services/user';
import Loading from '../Loading';

interface IProtected {
  role?: string;
  setRole: (role: string) => void;
}

const Protected = ({ role: currentRole, setRole }: IProtected) => {
  const location = useLocation();

  useEffect(() => {
    const handleSession = async () => {
      const sessionData = await supabase.auth.getSession();
      const currentUser = sessionData?.data.session?.user;

      if (!currentUser) {
        const redirectTo =
          window.location.pathname === '/'
            ? ''
            : `?redirectTo=${window.location.href}`;

        window.location.href = `/login${redirectTo}`;
        return;
      }

      const userService = new UserService(sessionData.data.session);
      const { data: userRole } = await userService.getUserDetail();
      const role = !!userRole.advisor ? 'advisor' : 'client';

      setRole(role);

      localStorage.setItem('user_role', role);

      if (!!userRole.advisor) {
        localStorage.setItem('partnerId', userRole.advisor.partnerId);
      }
    };

    handleSession();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  const isMobile =
    navigator.userAgent.match(/Android/i) ||
    navigator.userAgent.match(/webOS/i) ||
    navigator.userAgent.match(/iPhone/i) ||
    navigator.userAgent.match(/iPad/i) ||
    navigator.userAgent.match(/iPod/i) ||
    navigator.userAgent.match(/BlackBerry/i) ||
    navigator.userAgent.match(/Windows Phone/i);

  if (isMobile) {
    return <Navigate to={'/ismobile'} replace />;
  }

  if(!currentRole) {
    return <Loading variant={'FULL'} />
  }

  return <Outlet />;
};

export default Protected;
