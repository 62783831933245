import { IIconProps } from '../interface';

const SvgDollar = ({
  width = 24,
  height = 24,
  color = '#323232',
  type,
}: IIconProps) => {
  if (type === 'circle') {
    return (
      <svg
        width={width}
        height={height}
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M12 21.5C17.2467 21.5 21.5 17.2467 21.5 12C21.5 6.75328 17.2467 2.49998 12 2.49998C6.75329 2.49998 2.5 6.75328 2.5 12C2.5 17.2467 6.75329 21.5 12 21.5Z"
          stroke={color}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M12 7.5V8.5"
          stroke={color}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M12 16.5V15.5"
          stroke={color}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M9.8587 14.25C10.052 14.6468 10.3586 14.9773 10.7398 15.1998C11.1209 15.4223 11.5595 15.5268 12 15.5C12.5289 15.5578 13.0595 15.4074 13.4795 15.0807C13.8994 14.7541 14.1758 14.2768 14.25 13.75C14.25 13.3834 14.123 13.0282 13.8906 12.7447C13.6582 12.4612 13.3347 12.267 12.9753 12.1951L11.0247 11.805C10.6653 11.7331 10.3418 11.5389 10.1094 11.2554C9.87701 10.9719 9.75 10.6166 9.75 10.25C9.82423 9.72318 10.1006 9.24592 10.5205 8.91929C10.9405 8.59265 11.4711 8.44228 12 8.50002C12.4405 8.47326 12.8791 8.57774 13.2602 8.80024C13.6414 9.02273 13.948 9.35326 14.1413 9.75002"
          stroke={color}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    );
  }

  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_2212_40915)">
        <path
          d="M11.75 7V8.25"
          stroke={color}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M11.75 17V15.75"
          stroke={color}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M14.237 10C14.172 9.024 13.367 8.25 12.375 8.25H11.005C10.035 8.25 9.25 9.036 9.25 10.005C9.25 10.81 9.798 11.512 10.578 11.708L12.921 12.296C13.702 12.492 14.249 13.194 14.249 13.999C14.249 14.969 13.463 15.754 12.494 15.754H11.124C10.13 15.754 9.325 14.978 9.261 14"
          stroke={color}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M10.5 21H13.5"
          stroke={color}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M13.5 3H10.5"
          stroke={color}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M3 10.5V13.5"
          stroke={color}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M21 13.5V10.5"
          stroke={color}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M21 6V4.214C21 3.544 20.456 3 19.786 3H18"
          stroke={color}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M3 18V19.786C3 20.456 3.544 21 4.214 21H6"
          stroke={color}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M6 3H4.214C3.544 3 3 3.544 3 4.214V6"
          stroke={color}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M18 21H19.786C20.456 21 21 20.456 21 19.786V18"
          stroke={color}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_2212_40915">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default SvgDollar;
