import { IIconProps } from '../interface';

const SvgThailand = ({ width = 56, height = 56, type }: IIconProps) => {
  switch (type) {
    case 'circle':
      console.warn('Invalid option for this icon');
      return null;
    case 'md':
      console.warn('Invalid option for this icon');
      return null;
    case 'sm':
      console.warn('Invalid option for this icon');
      return null;
    case 'filled':
      console.warn('Invalid option for this icon');
      return null;
    case 'stripe':
      console.warn('Invalid option for this icon');
      return (
        <svg
          width={width}
          height={height}
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 16 16"
        >
          <g fill="none" fill-rule="evenodd">
            <path
              fill="#E25950"
              d="M1 2h14c.555 0 1 .448 1 1v1H0V3c0-.556.448-1 1-1ZM0 12h16v1c0 .556-.448 1-1 1H1c-.555 0-1-.448-1-1v-1Z"
            ></path>
            <path fill="#F6F9FC" d="M0 4h16v8H0z"></path>
            <path
              fill="#EAEEF3"
              fill-rule="nonzero"
              d="M15 12V4h1v8h-1ZM0 4h1v8H0V4Z"
            ></path>
            <path fill="#43458B" d="M0 5h16v6H0z"></path>
          </g>
        </svg>
      );
    default:
      return (
        <svg
          width={width}
          height={height}
          viewBox="0 0 56 56"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M28 56C43.464 56 56 43.464 56 28C56 12.536 43.464 0 28 0C12.536 0 0 12.536 0 28C0 43.464 12.536 56 28 56Z"
            fill="#F0F0F0"
          />
          <path
            d="M54.2584 18.2609H1.74158C0.616 21.2942 0 24.575 0 28C0 31.425 0.616 34.7058 1.74158 37.7391H54.2585C55.384 34.7058 56 31.425 56 28C56 24.575 55.384 21.2942 54.2584 18.2609Z"
            fill="#0052B4"
          />
          <path
            d="M28.0001 0C19.5136 0 11.9095 3.77606 6.77466 9.73908H49.2254C44.0907 3.77606 36.4866 0 28.0001 0Z"
            fill="#D80027"
          />
          <path
            d="M49.2255 46.2609H6.77466C11.9095 52.2239 19.5136 56 28.0001 56C36.4866 56 44.0907 52.2239 49.2255 46.2609Z"
            fill="#D80027"
          />
        </svg>
      );
  }
};

export default SvgThailand;
