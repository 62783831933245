const env = {
  NODE_ENV: process.env.NODE_ENV,
  APP_ENV: process.env.REACT_APP_ENV,
  APP_ADDRESS: process.env.REACT_APP_ADDRESS,

  APP_API: process.env.REACT_APP_API,

  APP_SENTRY_DSN: process.env.REACT_APP_SENTRY_DSN,

  APP_GA_TRACKING_ID: process.env.REACT_APP_GA_TRACKING_ID,

  APP_FRONT_VERIFICATION_SECRET:
    process.env.REACT_APP_FRONT_VERIFICATION_SECRET,
  APP_FRONT_CHAT_ID: process.env.REACT_APP_FRONT_CHAT_ID,
  APP_COMETCHAT_AUTH_KEY: process.env.REACT_APP_COMETCHAT_AUTH_KEY,

  APP_STRIPE: process.env.REACT_APP_STRIPE,

  APP_SUPABASE_ANON_KEY: process.env.REACT_APP_SUPABASE_ANON_KEY,
  APP_SUPABASE_URL: process.env.REACT_APP_SUPABASE_URL,

  APP_COMETCHAT_ID: process.env.REACT_APP_COMETCHAT_ID,
  APP_COMETCHAT_REGION: process.env.REACT_APP_COMETCHAT_REGION,

  APP_CONSULTATION_CALENDLY_ID: process.env.REACT_APP_CONSULTATION_CALENDLY_ID,

  APP_TYTLE_EMAIL: process.env.REACT_APP_TYTLE_EMAIL,
  APP_COMETCHAT_TYTLE_NAME: process.env.REACT_APP_COMETCHAT_TYTLE_NAME,

  APP_NOVU_IDENTIFIER: process.env.REACT_APP_NOVU_IDENTIFIER || "",
};

export default env;
