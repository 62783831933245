import { IIconProps } from '../interface';

const SvgArrowEnter = ({
  width = 24,
  height = 24,
  color = 'white',
}: IIconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_5362_614)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M21 11V3H23V11C23 12.6569 21.6569 14 20 14H4.41421L9.70711 19.2929L8.29289 20.7071L1.29289 13.7071C0.902369 13.3166 0.902369 12.6834 1.29289 12.2929L8.29289 5.29289L9.70711 6.70711L4.41421 12H20C20.5523 12 21 11.5523 21 11Z"
          fill={color}
        />
      </g>
      <defs>
        <clipPath id="clip0_5362_614">
          <rect width="24" height="24" fill={color} />
        </clipPath>
      </defs>
    </svg>
  );
};

export default SvgArrowEnter;
