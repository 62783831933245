import { IIconProps } from '../interface';

const SvgMoneyBankNoteArrow = ({
  width = 24,
  height = 24,
  color,
  type,
}: IIconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.5303 1.46967C12.2374 1.17678 11.7626 1.17678 11.4697 1.46967L8.46967 4.46967L9.53033 5.53033L11.25 3.81066V9.5H12.75V3.81066L14.4697 5.53033L15.5303 4.46967L12.5303 1.46967Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15 15C15 16.6569 13.6569 18 12 18C10.3431 18 9 16.6569 9 15C9 13.3431 10.3431 12 12 12C13.6569 12 15 13.3431 15 15ZM13.5 15C13.5 15.8284 12.8284 16.5 12 16.5C11.1716 16.5 10.5 15.8284 10.5 15C10.5 14.1716 11.1716 13.5 12 13.5C12.8284 13.5 13.5 14.1716 13.5 15Z"
        fill={color}
      />
      <path d="M7.5 13V17H6V13H7.5Z" fill={color} />
      <path d="M16.5 13H18V17H16.5V13Z" fill={color} />
      <path
        d="M5 8H8V9.5H5C4.17157 9.5 3.5 10.1716 3.5 11V19C3.5 19.8284 4.17157 20.5 5 20.5H19C19.8284 20.5 20.5 19.8284 20.5 19V11C20.5 10.1716 19.8284 9.5 19 9.5H16V8H19C20.6569 8 22 9.34315 22 11V19C22 20.6569 20.6569 22 19 22H5C3.34315 22 2 20.6569 2 19V11C2 9.34315 3.34315 8 5 8Z"
        fill={color}
      />
    </svg>
  );
};

export default SvgMoneyBankNoteArrow;
