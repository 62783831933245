import { IIconProps } from '../interface';

const SvgSwitzerland = ({ width = 56, height = 56, type }: IIconProps) => {
  switch (type) {
    case 'circle':
      console.warn('Invalid option for this icon');
      return null;
    case 'md':
      console.warn('Invalid option for this icon');
      return null;
    case 'sm':
      console.warn('Invalid option for this icon');
      return null;
    case 'filled':
      console.warn('Invalid option for this icon');
      return null;
    case 'stripe':
      console.warn('Invalid option for this icon');
      return (
        <svg
          width={width}
          height={height}
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 16 16"
        >
          <g fill="none" fill-rule="evenodd">
            <path
              fill="#E25950"
              d="M2 2h12a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V4a2 2 0 0 1 2-2Z"
            ></path>
            <path
              fill="#FFF"
              fill-rule="nonzero"
              d="M12.5 9.5h-3v3h-3v-3h-3v-3h3v-3h3v3h3z"
            ></path>
          </g>
        </svg>
      );
    default:
      return (
        <svg
          width={width}
          height={height}
          viewBox="0 0 56 56"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M28 56C43.464 56 56 43.464 56 28C56 12.536 43.464 0 28 0C12.536 0 0 12.536 0 28C0 43.464 12.536 56 28 56Z"
            fill="#D80027"
          />
          <path
            d="M42.6087 23.1305H32.8696V13.3913H23.1304V23.1305H13.3914V32.8696H23.1304V42.6087H32.8696V32.8696H42.6087V23.1305Z"
            fill="#F0F0F0"
          />
        </svg>
      );
  }
};

export default SvgSwitzerland;
