import timediff from 'timediff';
import * as VMasker from 'vanilla-masker';

export function formatToDecimalValue(value: string | number) {
  const number = formatToNumber(value);
  return parseFloat((parseInt(number) / 100).toFixed(2));
}

export function formatToNumber(value: string | number) {
  return VMasker.toNumber(value ?? '');
}

export function formatToPattern(value: string | number, format: string) {
  return VMasker.toPattern(value ?? '', format);
}

export const getTwoInitialLetters = (text: string) => {
  const splitedText = text.split(' ');

  if (splitedText.length > 1) {
    return [splitedText[0].slice(0, 1), splitedText[1].slice(0, 1)].join('');
  }

  return text.slice(0, 2);
};

export const buildIntervalDate = (date: string) => {
  const d: { [key: string]: number } = timediff(date, new Date(), 'YMWDHmSs');

  const time = Object.keys(d).reduce((acc, crr) => {
    if (d[crr] === 0) {
      return acc;
    }

    if (d[crr] > 1 && crr === 'seconds') {
      return [...acc, `some seconds ago`];
    }

    if (d[crr] === 1 && crr === 'days') {
      return [...acc, `yesterday`];
    }

    if (d[crr] === 1 && crr === 'hours') {
      return [...acc, `${d[crr]} hour ago`];
    }

    if (d[crr] <= 1 && crr === 'minutes') {
      return [...acc, `${d[crr]} minute ago`];
    }

    if (d[crr] === 1 && crr === 'weeks') {
      return [...acc, `${d[crr]} week ago`];
    }

    if (d[crr] === 1 && crr === 'months') {
      return [...acc, `${d[crr]} month ago`];
    }

    if (d[crr] === 1 && crr === 'years') {
      return [...acc, `${d[crr]} year ago`];
    }

    return [...acc, `${d[crr]} ${crr} ago`];
  }, [] as string[]);

  if (!time.length) {
    return 'some seconds';
  }

  return time[0];
};
