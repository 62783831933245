import { useEffect } from 'react';
import { supabase } from './supabaseClient';
import CryptoJS from 'crypto-js';
import env from './env';

const InitChat = () => {
  useEffect(() => {
    handleInitFront();
  }, []);

  const handleInitFront = async () => {
    if (document.querySelector('#front-chat-holder div')) return;

    const { data: sessionData } = await supabase.auth.getSession();

    const userEmail = sessionData?.session?.user.email || '';

    const verificationKey = env.APP_FRONT_VERIFICATION_SECRET || '';

    const userHash = CryptoJS.HmacSHA256(userEmail, verificationKey).toString();

    window.FrontChat('init', {
      chatId: env.APP_FRONT_CHAT_ID,
      useDefaultLauncher: false,
      email: userEmail,
      userHash,
      name: userEmail,
    });
  };

  return null;
};

export default InitChat;
