export interface ICurrentQuestion {
  id: string;
  category_id: string;
  fiscalYear?: string;
  initial?: string;
  done: boolean;
  ignore: boolean;
  aditionalQuestion: boolean;
  answers: string[];
  answersValues?: any[];
}

export const initialCurrentQuestionState = {
  id: '',
  done: false,
  category_id: '',
  aditionalQuestion: false,
  ignore: false,
  answers: [],
};

export const DEFAULT_COUNTRIES_LIMIT = 7;
