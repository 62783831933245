import { IIconProps } from '../interface';

const SvgBankBuilding = ({
  width = 24,
  height = 24,
  color,
  type,
}: IIconProps) => {
  return (
    <svg
      width={width}
      height={height}
      version="1.1"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g
        strokeLinecap="round"
        strokeWidth="1.5"
        stroke={color}
        fill="none"
        strokeLinejoin="round"
      >
        <path d="M12 7.221l-2.2715e-09-7.37188e-14c-.0684833-2.22574e-06-.124002.0555127-.124004.123996 -1.08773e-08.000334682 1.33323e-06.000669362 4.03228e-06.00100403l1.77636e-15 1.88745e-08c1.04241e-08.0690356.0559644.125.125.125 .0690356-1.04241e-08.125-.0559644.125-.125l4.64126e-09 2.95266e-07c-.00108406-.0689705-.0570228-.124465-.126-.125"></path>
        <path d="M20.972 10.333h-17.944l-2.41761e-09 4.58833e-12c-.291054.000552283-.527447-.234946-.527999-.525999 -6.3311e-07-.000333649-9.49364e-07-.000667299-9.48762e-07-.00100095v-1.976l9.85065e-09-1.28646e-05c.000332209-.430239.275817-.812041.684012-.947991l8.482-2.828 8.7034e-08-2.88958e-08c.216847-.0719947.451153-.0719947.668 5.77917e-08l8.482 2.828 -2.05803e-08-6.85461e-09c.408194.135956.683673.517761.684.948v1.976l8.98837e-13 9.722e-07c0 .291054-.235946.527-.527.527 -.000337749 0-.000675498-3.2469e-07-.00101325-9.74068e-07Z"></path>
        <path d="M4 18v-7.667"></path>
        <path d="M20 10.333v7.667"></path>
        <path d="M8 10.333v7.667"></path>
        <path d="M12 10.333v7.667"></path>
        <path d="M16 10.333v7.667"></path>
        <path d="M20.382 18h-16.764l4.60337e-08-2.45812e-11c-.378666.000202201-.724736.214271-.894.553l-.618 1.236 -8.64868e-09 1.71847e-08c-.0697877.138666-.106093.291763-.106.447v.764h20v-.764l-1.23538e-09 2.06939e-06c9.2835e-05-.155237-.0362117-.308334-.105999-.447l-.618-1.236 -1.43111e-08-2.86395e-08c-.169263-.33873-.515334-.5528-.894001-.553002Z"></path>
      </g>
      <rect width={width} height={height} fill="none"></rect>
      <line
        x1="23"
        x2="1"
        y1="21"
        y2="21"
        strokeLinecap="round"
        strokeWidth="1.5"
        stroke={color}
        fill="none"
        strokeLinejoin="round"
      ></line>
    </svg>
  );
};

export default SvgBankBuilding;
