import { IIconProps } from '../interface';

const SvgMyIconsBasketPack1 = ({
  width = 24,
  height = 24,
  color = '#323232',
}: IIconProps) => {
  return (
    <svg
      width={width}
      height={height}
      version="1.1"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g fill="none">
        <path d="M0,0h24v24h-24Z"></path>
        <path
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5"
          d="M20,12h-16c-0.552,0 -1,-0.448 -1,-1v-2c0,-0.552 0.448,-1 1,-1h16c0.552,0 1,0.448 1,1v2c0,0.552 -0.448,1 -1,1Z"
        ></path>
        <path
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5"
          d="M14.04,3.03l2.96,4.94"
        ></path>
        <path
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5"
          d="M9.96,3.03l-2.96,4.94"
        ></path>
        <path
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5"
          d="M20.5355,13.4645c1.95262,1.95262 1.95262,5.11845 0,7.07107c-1.95262,1.95262 -5.11845,1.95262 -7.07107,0c-1.95262,-1.95262 -1.95262,-5.11845 0,-7.07107c1.95262,-1.95262 5.11845,-1.95262 7.07107,0"
        ></path>
        <path
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5"
          d="M17,15v4"
        ></path>
        <path
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5"
          d="M19,17h-4"
        ></path>
        <path
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5"
          d="M13.963,20.972h-6.568c-0.706,0 -1.316,-0.492 -1.466,-1.182l-1.687,-7.79"
        ></path>
      </g>
    </svg>
  );
};

export default SvgMyIconsBasketPack1;
