import { IIconProps } from '../interface';

const SvgLithuania = ({ width = 56, height = 56, type }: IIconProps) => {
  if (type === 'stripe') {
    return (
      <svg
        width={width}
        height={height}
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 16 16"
      >
        <g fill="none" fill-rule="evenodd">
          <path
            fill="#FCD669"
            d="M1.994 2A1.992 1.992 0 0 0 0 4v2h16V4c0-1.105-.895-2-1.994-2H1.994Z"
          ></path>
          <path
            fill="#A68527"
            fill-opacity="0.1"
            fill-rule="nonzero"
            d="M15 6V4a1 1 0 0 0-1-1H2a1 1 0 0 0-1 1v2H0V4a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v2h-1Z"
          ></path>
          <path fill="#159570" d="M0 6h16v4H0z"></path>
          <path
            fill="#E25950"
            d="M0 10v2c0 1.105.895 2 1.994 2h12.012A1.992 1.992 0 0 0 16 12v-2H0Z"
          ></path>
        </g>
      </svg>
    );
  }

  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 56 56"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M54.2584 37.7391C55.384 34.7059 56 31.425 56 28C56 24.575 55.384 21.2941 54.2584 18.2609L28 15.8261L1.74158 18.2609C0.616 21.2941 0 24.575 0 28C0 31.425 0.616 34.7059 1.74158 37.7391L28 40.1739L54.2584 37.7391Z"
        fill="#6DA544"
      />
      <path
        d="M54.2585 18.2609C50.3023 7.5985 40.0391 0 28.0001 0C15.9611 0 5.6979 7.5985 1.7417 18.2609H54.2585Z"
        fill="#FFDA44"
      />
      <path
        d="M28.0001 56C40.0391 56 50.3023 48.4015 54.2585 37.7391H1.7417C5.6979 48.4015 15.9611 56 28.0001 56Z"
        fill="#D80027"
      />
    </svg>
  );
};

export default SvgLithuania;
