import { IIconProps } from '../interface';

const SvgDocument = ({ width = 24, height = 24, color, type }: IIconProps) => {
  switch (type) {
    case 'circle':
      console.warn('Invalid option for this icon');
      return null;
    case 'md':
      console.warn('Invalid option for this icon');
      return null;
    case 'sm':
      console.warn('Invalid option for this icon');
      return null;
    case 'filled':
      console.warn('Invalid option for this icon');
      return null;
    default:
      return (
        <svg
          width={width}
          height={height}
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g clipPath="url(#clip0_4431_35254)">
            <g clipPath="url(#clip1_4431_35254)">
              <path
                d="M4 5V19.5025C4 19.6878 4.05153 19.8696 4.14883 20.0274C4.24613 20.1851 4.38537 20.3128 4.551 20.396L5.541 20.8935C5.67935 20.963 5.83196 20.9995 5.9868 21C6.14164 21.0005 6.29448 20.965 6.43328 20.8964L8.00576 20.1188L9.54666 20.8929C9.68541 20.9626 9.83848 20.999 9.99375 20.9993C10.149 20.9996 10.3022 20.9637 10.4412 20.8945L12 20.1185L13.5588 20.8945C13.6978 20.9637 13.851 20.9996 14.0062 20.9993C14.1615 20.999 14.3146 20.9626 14.4533 20.8929L15.9942 20.1188L17.5667 20.8964C17.7055 20.965 17.8583 21.0005 18.0132 21C18.168 20.9995 18.3206 20.963 18.459 20.8935L19.449 20.396C19.6146 20.3128 19.7539 20.1851 19.8512 20.0274C19.9485 19.8696 20 19.6879 20 19.5025V5C20 4.46957 19.7893 3.96086 19.4142 3.58579C19.0391 3.21071 18.5304 3 18 3H6C5.46957 3 4.96086 3.21071 4.58579 3.58579C4.21071 3.96086 4 4.46957 4 5V5Z"
                stroke={color}
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M16 8H8"
                stroke={color}
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M8 11.5H16"
                stroke={color}
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M8 15H16"
                stroke={color}
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </g>
          </g>
          <defs>
            <clipPath id="clip0_4431_35254">
              <rect width={width} height={height} fill="white" />
            </clipPath>
            <clipPath id="clip1_4431_35254">
              <rect width={width} height={height} fill="white" />
            </clipPath>
          </defs>
        </svg>
      );
  }
};

export default SvgDocument;
