import { IIconProps } from '../interface';

const SvgMyIconsCompassMap = ({
  width = 24,
  height = 24,
  color = '#323232',
}: IIconProps) => {
  return (
    <svg
      width={width}
      height={height}
      version="1.1"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g
        strokeLinecap="round"
        strokeWidth="1.5"
        stroke={color}
        fill="none"
        strokeLinejoin="round"
      >
        <path d="M12,21c4.95,0 9,-4.05 9,-9c0,-4.95 -4.05,-9 -9,-9c-4.95,0 -9,4.05 -9,9c0,4.95 4.05,9 9,9Z"></path>
        <path d="M10.236,9.553l4.928,-1.327c0.372,-0.1 0.712,0.24 0.613,0.612l-1.307,4.902c-0.092,0.345 -0.361,0.615 -0.706,0.708l-4.928,1.327c-0.372,0.1 -0.712,-0.24 -0.613,-0.612l1.307,-4.902c0.092,-0.345 0.361,-0.615 0.706,-0.708Z"></path>
      </g>
      <path fill="none" d="M0,0h24v24h-24Z"></path>
      <g
        strokeLinecap="round"
        strokeWidth="1.5"
        stroke={color}
        fill="none"
        strokeLinejoin="round"
      >
        <path d="M11.999,11.75c-0.138,0 -0.25,0.112 -0.249,0.25c0,0.138 0.112,0.25 0.25,0.25c0.138,0 0.25,-0.112 0.25,-0.25c0,-0.138 -0.112,-0.25 -0.251,-0.25"></path>
        <path d="M12,3v1.5"></path>
        <path d="M12,21v-1.5"></path>
        <path d="M21,12h-1.5"></path>
        <path d="M3,12h1.5"></path>
      </g>
    </svg>
  );
};

export default SvgMyIconsCompassMap;
