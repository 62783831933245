import { IIconProps } from '../interface';

const SvgMyIconsWorldNetworkSmartHome = ({
  width = 24,
  height = 24,
  color = '#323232',
}: IIconProps) => {
  return (
    <svg
      width={width}
      height={height}
      version="1.1"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g
        strokeLinecap="round"
        strokeWidth="1.5"
        stroke={color}
        fill="none"
        strokeLinejoin="round"
      >
        <path d="M11 21c-4.971 0-9-4.029-9-9 0-4.971 4.029-9 9-9 4.971 0 9 4.029 9 9"></path>
        <line x1="2.51" x2="19.49" y1="9" y2="9"></line>
        <line x1="2.51" x2="12" y1="15" y2="15"></line>
        <path d="M15 12c0-2.764-.723-5.528-2.167-7.94 -.847-1.413-2.819-1.413-3.665 0 -2.89 4.825-2.89 11.056 0 15.881 .423.706 1.128 1.06 1.833 1.06"></path>
        <path d="M20.686 17.721l-2.267-1.587c-.252-.176-.589-.176-.841 0l-2.266 1.586c-.196.137-.312.362-.312.6v2.946c0 .406.328.734.733.734h4.533c.406 0 .734-.328.734-.733v-2.947c-.002-.238-.118-.462-.314-.599Z"></path>
      </g>
      <path
        fill="none"
        d="M24 0v24h-24v-24h24Z"
        transform="matrix(4.50198e-11 1 -1 4.50198e-11 24 -1.08048e-09)"
      ></path>
    </svg>
  );
};

export default SvgMyIconsWorldNetworkSmartHome;
