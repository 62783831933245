import { IIconProps } from '../interface';

const SvgMyIconsBuildingEmployee = ({
  width = 24,
  height = 24,
  color = '#323232',
}: IIconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g fill="none">
        <path d="M0 0h24v24H0Z" />
        <g
          strokeLinecap="round"
          strokeWidth="1.5"
          stroke={color}
          fill="none"
          strokeLinejoin="round"
        >
          <path d="M12 5.5L12 8" />
          <path d="M9.5 11V9l0 0c-.01-.56.44-1.01.99-1.01h3l-.01 0c.55-.01 1 .44 1 .99v2" />
          <path d="M7 20v-8l0 0c-.01-.56.44-1.01.99-1.01h6.5" />
          <path d="M9.5 14l3.5 0" />
          <path d="M9.5 17l2.5 0" />
          <path d="M11 20H7l-.01-.001c-2.21-.01-4-1.8-4-4.01 0 0 0-.001 0-.001v-10l0 0c-.01-2.21 1.79-4.01 3.99-4.01h10l-.01 0c2.2-.01 4 1.79 4 3.99v4" />
          <path d="M21.5 22H15h-.01c-.28-.01-.5-.23-.5-.51 0 0 0 0 0 0v-.41l-.01 0c0-1.16.93-2.1 2.09-2.1h3.31l0 0c1.15 0 2.09.93 2.09 2.09v.4h-.001c0 .27-.23.5-.5.5 0 0-.001 0-.001 0Z" />
          <path d="M18.25 16.5l-.01-.001c-1.11-.01-2-.9-2-2.01 0-1.11.89-2 2-2 1.1 0 1.99.89 1.99 2 -.01 1.1-.9 1.99-2 1.99Z" />
        </g>
      </g>
    </svg>
  );
};

export default SvgMyIconsBuildingEmployee;
