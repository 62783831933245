import { useEffect } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import { supabase } from '../../supabaseClient';
import checkSession from '../../utils/checkSession';

const CheckSession = () => {
  const location = useLocation();

  useEffect(() => {
    const handleSession = async () => {
      const sessionData = await supabase.auth.getSession();
      const currentUser = sessionData?.data.session?.user;

      if (currentUser) {
        checkSession(currentUser);
        return;
      }
    };

    handleSession();
  }, [location]);

  useEffect(() => {
    const handleSession = async () => {
      const { data: authListener } = supabase.auth.onAuthStateChange(
        async (_, session) => {
          const currentUser = session?.user;

          checkSession(currentUser);
        }
      );

      return () => {
        authListener.subscription.unsubscribe();
      };
    };

    handleSession();
  }, []);

  return <Outlet />;
};

export default CheckSession;
