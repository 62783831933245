import { createSlice } from '@reduxjs/toolkit';

export interface IStateAdvice {
  role: string;
}

const initialState: IStateAdvice = {
  role: '',
};

export const adviceReducer = createSlice({
  name: 'tatool',
  initialState,
  reducers: {
    setRole: (state, action) => {
      state.role = action.payload;
    },
  },
});

export const { setRole } = adviceReducer.actions;

export default adviceReducer.reducer;
