import { IIconProps } from '../interface';

const SvgNewZealand = ({ width = 56, height = 56, type }: IIconProps) => {
  switch (type) {
    case 'circle':
      console.warn('Invalid option for this icon');
      return null;
    case 'md':
      console.warn('Invalid option for this icon');
      return null;
    case 'sm':
      console.warn('Invalid option for this icon');
      return null;
    case 'filled':
      console.warn('Invalid option for this icon');
      return null;
    case 'stripe':
      console.warn('Invalid option for this icon');
      return (
        <svg
          width={width}
          height={height}
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 16 16"
        >
          <g fill="none" fill-rule="evenodd">
            <path
              fill="#43458B"
              fill-rule="nonzero"
              d="M2 2h12a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V4a2 2 0 0 1 2-2Z"
            ></path>
            <path
              fill="#FFF"
              d="M1.346 5.964H0V4.036h3v-.107L.748 2.44c.267-.213.59-.36.943-.415L3 2.89V2h2v2.036h.046L8 2.08v1.04l-1.383.915H8v1.928H5v.083l2.934 1.941h-1.57L5 7.086V8H3V5.964h-.082L0 7.894v-1.04l1.346-.89Z"
            ></path>
            <path
              fill="#E25950"
              d="M3 3.93v.106H1.587L.203 3.12c.13-.265.317-.497.545-.68L3 3.93Zm2-.881L6.586 2H8v.104L5.08 4.036H5v-.987ZM2.92 5.964H3v.987L1.414 8H0v-.104l2.92-1.932Zm3.493 0L8 7.014V8h-.08L5 6.07v-.106h1.413ZM8 5.427H4.444V8h-.888V5.427H0v-.854h3.556V2h.888v2.573H8v.854Z"
            ></path>
            <path
              fill="#E25950"
              fill-rule="nonzero"
              d="M14 6.5a.5.5 0 1 1 1 0 .5.5 0 0 1-1 0Zm-2 5a.5.5 0 1 1 1 0 .5.5 0 0 1-1 0Zm.067-6.75a.5.5 0 1 1 .866-.5.5.5 0 0 1-.866.5ZM11 6.5c0-.276.22-.5.49-.5a.489.489 0 0 1 .441.245.508.508 0 0 1 0 .51.489.489 0 0 1-.44.245.495.495 0 0 1-.491-.5Z"
            ></path>
          </g>
        </svg>
      );
    default:
      return (
        <svg
          width={width}
          height={height}
          viewBox="0 0 56 56"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M56 28C56 43.4639 43.4639 56 28 56C12.5361 56 0 43.4639 0 28C0 28.0067 28 0.0030625 28 0C43.4639 0 56 12.5361 56 28Z"
            fill="#0052B4"
          />
          <path
            d="M27.925 28H28C28 27.9748 28 27.9503 28 27.9251C27.975 27.9501 27.9501 27.9751 27.925 28Z"
            fill="#F0F0F0"
          />
          <path
            d="M28 14.6087C28 9.68188 28 6.45367 28 0H27.9953C12.5335 0.002625 0 12.5375 0 28H14.6087V19.7738L22.835 28H27.9252C27.9501 27.9751 27.9752 27.9501 28.0001 27.9251C28.0001 26.0387 28.0001 24.3555 28.0001 22.8351L19.7737 14.6087H28Z"
            fill="#F0F0F0"
          />
          <path
            d="M14.1657 3.65215C9.7864 6.14579 6.14574 9.78644 3.6521 14.1657V28H10.9565V10.9568V10.9565H28C28 8.65277 28 6.45805 28 3.65215H14.1657Z"
            fill="#D80027"
          />
          <path
            d="M27.9999 24.5568L18.0519 14.6088C18.0519 14.6088 14.6086 14.609 14.6086 14.6088V14.609L27.9998 28H27.9999C27.9999 28 27.9999 25.6258 27.9999 24.5568Z"
            fill="#D80027"
          />
          <path
            d="M48.4892 20.7768L49.0935 22.6368H51.0492L49.467 23.7863L50.0714 25.6463L48.4892 24.4968L46.9069 25.6463L47.5113 23.7863L45.929 22.6368H47.8847L48.4892 20.7768Z"
            fill="#D80027"
          />
          <path
            d="M41.4981 34.168L42.4047 36.9582H45.3383L42.9648 38.6823L43.8716 41.4724L41.4981 39.7481L39.1247 41.4724L40.0314 38.6823L37.658 36.9582H40.5915L41.4981 34.168Z"
            fill="#D80027"
          />
          <path
            d="M41.762 12.255L42.5176 14.5802H44.9622L42.9843 16.0169L43.7398 18.342L41.762 16.9049L39.7843 18.342L40.5397 16.0169L38.562 14.5802H41.0065L41.762 12.255Z"
            fill="#D80027"
          />
          <path
            d="M35.0352 20.6956L35.9417 23.4858H38.8754L36.5019 25.2099L37.4087 28L35.0352 26.2757L32.6618 28L33.5685 25.2099L31.1951 23.4858H34.1286L35.0352 20.6956Z"
            fill="#D80027"
          />
        </svg>
      );
  }
};

export default SvgNewZealand;
