import React from 'react';
import { createRoot } from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals';
import './languages';
import { Provider } from 'react-redux';
import store from './store';
import Routes from './Routes';
import { Toaster } from 'react-hot-toast';
import * as Sentry from '@sentry/react';
import ReactGA from 'react-ga4';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import InitChat from './InitChat';
import env from './env';
import { t } from 'i18next';
import GenericError from './components/genericError';
import { ErrorBoundary } from 'react-error-boundary';
import { PersistGate } from 'redux-persist/integration/react';
import { persistStore } from 'redux-persist';
import pjson from '../package.json';

const container = document.getElementById('root');

const root = createRoot(container!);

let persistor = persistStore(store);

ReactGA.initialize(env.APP_GA_TRACKING_ID || '');
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      suspense: true,
    },
  },
});

Sentry.init({
  dsn: env.APP_SENTRY_DSN,
  environment: env.APP_ENV,
  integrations: [Sentry.replayIntegration()],
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0,
  tracesSampleRate: 1.0,
  ignoreErrors: ['ChunkLoadError', /chunk/gi],
});

root.render(
  <ErrorBoundary
    fallback={
      <GenericError
        action={() => (window.location.href = '/')}
        actionText={t('go_back')}
      />
    }
  >
    <QueryClientProvider client={queryClient}>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <Routes />
          <Toaster />
          <ReactQueryDevtools />
          <InitChat />
        </PersistGate>
      </Provider>
    </QueryClientProvider>
  </ErrorBoundary>
);

console.log(`app version - ${pjson?.version}`);
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
