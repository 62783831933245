import { IIconProps } from '../interface';

const SvgDoctor = ({
  width = 24,
  height = 24,
  color = '#323232',
}: IIconProps) => {
  return (
    <svg
    width={width}
    height={height}
    id="Layer_3"
    data-name="Layer 3"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
  >
    <rect width="24" height="24" fill="none" />
    <path
      d="M10,2h4a2,2,0,0,1,2,2V8a4,4,0,0,1-4,4h0A4,4,0,0,1,8,8V4A2,2,0,0,1,10,2Z"
      fill="none"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
    />
    <line
      x1="14.101"
      y1="11.405"
      x2="14.844"
      y2="14.377"
      fill="none"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
    />
    <line
      x1="9.901"
      y1="11.405"
      x2="9.158"
      y2="14.377"
      fill="none"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
    />
    <line
      x1="8"
      y1="14.15658"
      x2="8"
      y2="18"
      fill="none"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
    />
    <circle
      cx="8"
      cy="19.5"
      r="1.5"
      fill="none"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
    />
    <line
      x1="16"
      y1="14.15658"
      x2="16"
      y2="18"
      fill="none"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
    />
    <path
      d="M14.27753,21A1.99271,1.99271,0,0,1,14,20h0a2,2,0,0,1,2-2h0a2,2,0,0,1,2,2h0a1.99271,1.99271,0,0,1-.27753,1"
      fill="none"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
    />
    <line
      x1="16"
      y1="6"
      x2="8"
      y2="6"
      fill="none"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
    />
    <path
      d="M3,19V17.61581A3,3,0,0,1,5.41185,14.674L8.258,14.105a.905.905,0,0,1,.679.134h0a5.52,5.52,0,0,0,6.126,0h0a.905.905,0,0,1,.679-.134l2.84615.569A3,3,0,0,1,21,17.61581V19"
      fill="none"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
    />
  </svg>
  );
};

export default SvgDoctor;

