import { IIconProps } from '../interface';

const SvgMyIconsTravelEarth = ({
  width = 24,
  height = 24,
  color = '#323232',
}: IIconProps) => {
  return (
    <svg
      width={width}
      height={height}
      version="1.1"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g
        strokeLinecap="round"
        strokeWidth="1.5"
        stroke={color}
        fill="none"
        strokeLinejoin="round"
      >
        <path d="M17.924,3.992h-1.833v1.833"></path>
        <path d="M12,20.556c2.19,0 4.379,-0.835 6.05,-2.506c3.341,-3.341 3.341,-8.758 0,-12.099c-0.594,-0.594 -1.256,-1.078 -1.959,-1.461"></path>
        <path d="M12,6.958c-2.689,2.791 -2.689,7.292 0,10.083c2.689,-2.79 2.689,-7.292 0,-10.083Z"></path>
        <path d="M12,6.958c2.791,0 5.042,2.251 5.042,5.042c0,2.791 -2.251,5.042 -5.042,5.042"></path>
        <path d="M12,17.042c-2.791,0 -5.042,-2.251 -5.042,-5.042c0,-2.791 2.251,-5.042 5.042,-5.042"></path>
        <path d="M6.96,12h10.08"></path>
        <path d="M7.909,18.175v1.833h-1.833"></path>
        <path d="M7.909,19.51c-0.702,-0.383 -1.364,-0.866 -1.959,-1.461c-3.341,-3.341 -3.341,-8.758 0,-12.099v-0.005c1.671,-1.671 3.86,-2.506 6.05,-2.506"></path>
      </g>
      <path fill="none" d="M0,0h24v24h-24v-24Z"></path>
    </svg>
  );
};

export default SvgMyIconsTravelEarth;
