import { IIconProps } from '../interface';

const SvgGreece = ({ width = 56, height = 56, type }: IIconProps) => {
  if (type === 'stripe') {
    return (
      <svg
        width={width}
        height={height}
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 16 16"
      >
        <g fill="none" fill-rule="evenodd">
          <path
            fill="#43458B"
            fill-rule="nonzero"
            d="M14 14H2a2 2 0 0 1-2-2V4a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2Z"
          ></path>
          <path
            fill="#F6F9FC"
            fill-rule="nonzero"
            d="M0 11h16v1H0v-1Zm14 3H2a2 2 0 0 1-1.732-1h15.464A2 2 0 0 1 14 14ZM0 9h16v1H0V9Zm0-2h16v1H0V7Zm5-2h11v1H5V5Zm0-2h10.723c.171.295.277.634.277 1H5V3Z"
          ></path>
          <path fill="#FFF" d="M2 4V2h1v2h2v1H3v2H2V5H0V4z"></path>
          <path
            fill="#43458B"
            fill-opacity="0.1"
            fill-rule="nonzero"
            d="M14 14H2a2 2 0 0 1-2-2V4a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2Zm1-10a1 1 0 0 0-1-1H2a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V4Z"
          ></path>
        </g>
      </svg>
    );
  }

  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 56 56"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M28 56C43.464 56 56 43.464 56 28C56 12.536 43.464 0 28 0C12.536 0 0 12.536 0 28C0 43.464 12.536 56 28 56Z"
        fill="#F0F0F0"
      />
      <path
        d="M28 20.6956H55.0354C54.3333 18.0909 53.2663 15.6365 51.8903 13.3912H28V20.6956Z"
        fill="#338AF3"
      />
      <path
        d="M10.5704 49.9131H45.4296C47.9849 47.8778 50.1763 45.4057 51.8903 42.6087H4.10968C5.8237 45.4056 8.01513 47.8778 10.5704 49.9131Z"
        fill="#338AF3"
      />
      <path
        d="M9.7391 6.77457C7.53782 8.67014 5.63481 10.9024 4.10968 13.3913H9.7391V6.77457Z"
        fill="#338AF3"
      />
      <path
        d="M28 28C28 25.1479 28 22.7611 28 20.6956H17.0435V28H9.73908V20.6956H0.964578C0.336875 23.0246 0 25.4727 0 28C0 30.5273 0.336875 32.9754 0.964578 35.3044H55.0355C55.6631 32.9754 56 30.5273 56 28H28Z"
        fill="#338AF3"
      />
      <path
        d="M28 0C24.1118 0 20.4089 0.793297 17.0435 2.22556V13.3913H28C28 10.8416 28 8.673 28 6.08694H45.4297C40.6466 2.27763 34.5897 0 28 0Z"
        fill="#338AF3"
      />
    </svg>
  );
};

export default SvgGreece;
