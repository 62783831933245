import { IIconProps } from '../interface';

const SvgMyIconsPassportTicketTravel = ({
  width = 24,
  height = 24,
  color = '#323232',
}: IIconProps) => {
  return (
    <svg
      width={width}
      height={height}
      version="1.1"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g fill="none">
        <path d="M0,0h24v24h-24Z"></path>
        <path
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5"
          d="M12,5c-2.593,2.768 -2.593,7.232 0,10c2.593,-2.768 2.593,-7.232 0,-10Z"
        ></path>
        <path
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5"
          d="M12,5c2.768,0 5,2.232 5,5c0,2.768 -2.232,5 -5,5"
        ></path>
        <path
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5"
          d="M12,15c-2.768,0 -5,-2.232 -5,-5c0,-2.768 2.232,-5 5,-5"
        ></path>
        <path
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5"
          d="M7,10h10"
        ></path>
        <path
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5"
          d="M17.778,22h-11.556c-1.227,0 -2.222,-0.995 -2.222,-2.222v-15.556c0,-1.227 0.995,-2.222 2.222,-2.222h11.556c1.227,0 2.222,0.995 2.222,2.222v15.556c0,1.227 -0.995,2.222 -2.222,2.222Z"
        ></path>
        <path
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5"
          d="M15,18.5h-6"
        ></path>
      </g>
    </svg>
  );
};

export default SvgMyIconsPassportTicketTravel;
