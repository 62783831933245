import { IIconProps } from '../interface';

const SvgMyIconsTravelVacationHolidayUmbrella = ({
  width = 24,
  height = 24,
  color = '#323232',
}: IIconProps) => {
  return (
    <svg
      width={width}
      height={height}
      version="1.1"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g
        strokeLinecap="round"
        strokeWidth="1.5"
        stroke={color}
        fill="none"
        strokeLinejoin="round"
      >
        <path d="M4,9c0,-3 4,-6 8,-6c4,0 8,3 8,6c0,0 -4,0 -8,0c-4,0 -8,0 -8,0Z"></path>
        <path d="M12,15v-6"></path>
        <path d="M9,8.95c0,-3.258 1.343,-5.9 3,-5.9c1.657,0 3,2.642 3,5.9"></path>
        <path d="M7.09,18l-1.09,3"></path>
        <path d="M15.91,18l1.09,3"></path>
        <path d="M4.5,18h11.098c1.082,0 2.135,-0.351 3,-1l1.802,-1.351c0.517,-0.387 0.727,-1.062 0.523,-1.674v0c-0.324,-0.971 -1.504,-1.34 -2.323,-0.726l-1.802,1.351c-0.346,0.26 -0.767,0.4 -1.2,0.4h-11.098c-0.828,0 -1.5,0.672 -1.5,1.5v0c0,0.828 0.672,1.5 1.5,1.5Z"></path>
      </g>
      <path fill="none" d="M0,0h24v24h-24Z"></path>
    </svg>
  );
};

export default SvgMyIconsTravelVacationHolidayUmbrella;
