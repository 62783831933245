import { IIconProps } from '../interface';

const SvgShortLogo = ({ width = 19, height = 19 }: IIconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 19 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.6572 11.4256C15.2225 12.331 17.7233 6.29531 13.9867 3.40681L16.0658 1.76855C21.8815 5.87856 18.2612 16.4985 10.2501 14.9177C6.35353 14.1417 5.01591 14.8171 3.15487 18.6253C2.96586 19.0133 2.44245 19.1139 2.13712 18.7978L0.276086 16.9871C0.0289177 16.7428 -0.043779 16.3548 0.130693 16.0386C2.54422 11.5406 5.13222 10.3335 10.6572 11.4256Z"
        fill="#FF6948"
      />
      <path
        d="M10.6572 11.4256C15.2225 12.331 17.7233 6.29531 13.9867 3.40681L16.0658 1.76855C21.8815 5.87856 18.2612 16.4985 10.2501 14.9177C6.35353 14.1417 5.01591 14.8171 3.15487 18.6253C2.96586 19.0133 2.44245 19.1139 2.13712 18.7978L0.276086 16.9871C0.0289177 16.7428 -0.043779 16.3548 0.130693 16.0386C2.54422 11.5406 5.13222 10.3335 10.6572 11.4256Z"
        fill="url(#paint0_linear_4479_67579)"
      />
      <path
        d="M17.6942 11.7418C18.0868 11.052 18.3339 10.2904 18.4212 9.48561C18.4939 8.69522 18.4066 7.89047 18.1595 7.14319C17.9123 6.39592 17.4907 5.69175 16.9527 5.11693C16.4147 4.5421 15.7605 4.08224 15.0335 3.78045C14.3065 3.47867 13.5214 3.34933 12.7363 3.37808C11.9512 3.40682 11.1806 3.62238 10.4827 3.98164C9.78481 4.34091 9.17416 4.85825 8.67982 5.47619C8.34542 5.90731 8.08371 6.38155 7.88016 6.88452C7.76384 7.1863 7.35674 7.30127 7.12411 7.05697L5.37939 5.275C5.19038 5.07381 5.10314 4.7864 5.17584 4.52773C5.42301 3.72297 5.84465 2.99007 6.42622 2.37213C6.4553 2.34339 6.48438 2.30028 6.528 2.27153C6.57162 2.24279 6.6007 2.19968 6.62977 2.17094C7.92378 0.906322 9.65396 0.159047 11.4568 0.101565C13.2597 0.0440819 15.019 0.676391 16.4002 1.85479C17.7814 3.04755 18.6683 4.70018 18.901 6.52525C19.1336 8.35033 18.712 10.2185 17.6942 11.7418Z"
        fill="#FF6948"
      />
      <defs>
        <linearGradient
          id="paint0_linear_4479_67579"
          x1="13.7983"
          y1="1.27101"
          x2="9.95648"
          y2="11.6"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#9B1C00" />
          <stop offset="1" stopColor="#FF6948" stopOpacity="0" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default SvgShortLogo;
