import { IIconProps } from '../interface';

const SvgCzechRepublic = ({ width = 56, height = 56, type }: IIconProps) => {
  if (type === 'stripe') {
    return (
      <svg
        width={width}
        height={height}
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 16 16"
      >
        <g fill="none" fill-rule="evenodd" transform="translate(0 2)">
          <rect width="16" height="12" fill="#F6F9FC" rx="2"></rect>
          <path
            fill="#EAEEF3"
            fill-rule="nonzero"
            d="M1.51 1.128.8.596A.5.5 0 0 0 .656.52 2.01 2.01 0 0 0 0 2.009V2a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v4h-1V2a1 1 0 0 0-1-1H2a.995.995 0 0 0-.49.128Z"
          ></path>
          <path
            fill="#E25950"
            d="M0 6v4.002C0 11.106.895 12 1.994 12h12.012A1.998 1.998 0 0 0 16 10.002V6H0Z"
          ></path>
          <path
            fill="#43458B"
            d="M.649 11.473A2.01 2.01 0 0 1 0 9.991V2.01C0 1.419.253.889.656.52A.5.5 0 0 1 .8.596l7.2 5.4-7.2 5.4a.5.5 0 0 1-.151.077Z"
          ></path>
        </g>
      </svg>
    );
  }

  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 56 56"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_172_15525)">
        <path
          d="M28 56C43.464 56 56 43.464 56 28C56 12.536 43.464 0 28 0C12.536 0 0 12.536 0 28C0 43.464 12.536 56 28 56Z"
          fill="#F0F0F0"
        />
        <path
          d="M25.5652 28C25.5652 28 8.21733 47.8029 8.20093 47.799C13.2678 52.866 20.2679 56 28 56C43.4639 56 56 43.4639 56 28H25.5652Z"
          fill="#D80027"
        />
        <path
          d="M8.20099 8.20094C-2.73366 19.1356 -2.73366 36.8643 8.20099 47.799C12.7196 43.2804 17.0654 38.9346 28 28L8.20099 8.20094Z"
          fill="#0052B4"
        />
      </g>
      <defs>
        <clipPath id="clip0_172_15525">
          <rect width="56" height="56" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default SvgCzechRepublic;
