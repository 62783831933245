import { IIconProps } from '../interface';

const SvgMyIconsReceiptItemList = ({
  width = 24,
  height = 24,
  color = '#323232',
}: IIconProps) => {
  return (
    <svg
      width={width}
      height={height}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
    >
      <path
        d="M6,17V4.99622a1.5,1.5,0,0,1,.82647-1.34028l.98578-.49538a1.5,1.5,0,0,1,1.33843-.00431l1.85117.91541h-.00721l1.83687-.91446a1.5,1.5,0,0,1,1.337,0l1.83687.91446h-.00721l1.85117-.91541a1.5,1.5,0,0,1,1.33843.00431l.98578.49538A1.5,1.5,0,0,1,21,4.99622V19.5A1.5,1.5,0,0,1,19.5,21h0A1.5,1.5,0,0,1,18,19.5V18a1,1,0,0,0-1-1H4a1,1,0,0,0-1,1v1a2,2,0,0,0,2,2H19.5"
        fill="none"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
      />
      <line
        x1="10"
        y1="9"
        x2="17"
        y2="9"
        fill="none"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
      />
      <line
        x1="10"
        y1="13"
        x2="17"
        y2="13"
        fill="none"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
      />
      <path d="M0,24H24V0H0Z" fill="none" />
    </svg>
  );
};

export default SvgMyIconsReceiptItemList;
