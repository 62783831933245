import { IIconProps } from '../interface';

const SvgHomeCustomer = ({
  width = 24,
  height = 24,
  color = '#323232',
}: IIconProps) => {
  return (
    <svg
      width={width}
      height={height}
      id="Layer_3"
      data-name="Layer 3"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
    >
      <path d="M0,0H24V24H0Z" fill="none" />
      <path
        d="M14,21V18.08666a2,2,0,0,1,.4-1.2l1.67794-2.23727a1.62336,1.62336,0,0,1,2.44657-.17388h0a1.62335,1.62335,0,0,1,.15079,2.1219L17.62335,18"
        fill="none"
        stroke={color}
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="1.5"
      />
      <path
        d="M21,21l.5777-1.15546A4.00041,4.00041,0,0,0,22,18.05557V11.5A1.5,1.5,0,0,0,20.5,10h0A1.5,1.5,0,0,0,19,11.5v4.10706"
        fill="none"
        stroke={color}
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="1.5"
      />
      <path
        d="M10,21V18.08666a2,2,0,0,0-.4-1.2L7.92206,14.64939a1.62336,1.62336,0,0,0-2.44657-.17388h0a1.62335,1.62335,0,0,0-.15079,2.1219L6.37665,18"
        fill="none"
        stroke={color}
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="1.5"
      />
      <path
        d="M3,21l-.5777-1.15546A4.00041,4.00041,0,0,1,2,18.05557V11.5A1.5,1.5,0,0,1,3.5,10h0A1.5,1.5,0,0,1,5,11.5v4.10706"
        fill="none"
        stroke={color}
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="1.5"
      />
      <path
        d="M15,11H9a1,1,0,0,1-1-1V6.46153a1.5,1.5,0,0,1,.563-1.17132l2.452-1.96152a1.5,1.5,0,0,1,1.87405,0L15.437,5.367A1.5,1.5,0,0,1,16,6.53835V10A1,1,0,0,1,15,11Z"
        fill="none"
        stroke={color}
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="1.5"
      />
    </svg>
  );
};

export default SvgHomeCustomer;
