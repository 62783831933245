import { Outlet } from 'react-router-dom';
import { Grid } from '../../design-system';
import Sidebar from '../sidebar';

const AppLayout = ({ short }: { short?: boolean }) => {
  return (
    <Grid
      className="App flex min-h-screen h-full bg-base-gray25 flex-col justify-between"
      fluid
      noPadding
    >
      <Grid noPadding fluid className="msm:flex-col flex-1">
        <Sidebar variant="APP" short={short} />
        <div
          className={`flex flex-col w-full ${
            !short && 'md:pl-spacing-270 md:pr-spacing-128'
          }`}
        >
          <Outlet />
        </div>
      </Grid>
    </Grid>
  );
};

export default AppLayout;
