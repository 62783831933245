import {
  NovuProvider,
  PopoverNotificationCenter,
  NotificationBell,
  IMessage,
} from '@novu/notification-center';
import { useEffect, useState } from 'react';
import { supabase } from '../../supabaseClient';
import { useNavigate } from 'react-router-dom';
import env from '../../env';

const Novu = () => {
  const navigate = useNavigate();
  const [userId, setUserId] = useState<string>('');
  const getUserData = async () => {
    const { data: sessionData } = await supabase.auth.getSession();
    const user = sessionData?.session?.user;
    setUserId(user?.id || '');
  };
  useEffect(() => {
    getUserData();
  }, []);

  function isMessagePayload(payload: any): payload is { redirectTo?: string } {
    return typeof payload === 'object' && 'redirectTo' in payload;
  }

  function handleOnNotificationClick(message: IMessage) {
    if (isMessagePayload(message.payload) && message.payload.redirectTo) {
      navigate(message.payload.redirectTo);
    }
  }
  return (
    <NovuProvider subscriberId={userId} applicationIdentifier={env.APP_NOVU_IDENTIFIER}>
      <PopoverNotificationCenter
        colorScheme="light"
        onNotificationClick={handleOnNotificationClick}
      >
        {({ unseenCount }) => <NotificationBell unseenCount={unseenCount} />}
      </PopoverNotificationCenter>
    </NovuProvider>
  );
};

export default Novu;
