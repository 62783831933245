import { IIconProps } from '../interface';

const SvgLock = ({
  width = 32,
  height = 32,
  color = '#323232',
  type,
}: IIconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_4566_68277)">
        <path
          d="M2.99902 9V18C2.99902 19.657 4.34202 21 5.99902 21H17.999C19.656 21 20.999 19.657 20.999 18V9"
          stroke={color}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M4.39788 6.765L10.2589 2.562C11.3039 1.812 12.7109 1.813 13.7559 2.563L19.5919 6.754C21.4609 8.096 21.4599 10.291 19.5899 11.631L13.7399 15.826C12.6949 16.576 11.2879 16.575 10.2429 15.825L4.39588 11.626C2.53388 10.288 2.53488 8.101 4.39788 6.765Z"
          stroke={color}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M18.002 12.77V11C18.002 9.895 17.107 9 16.002 9H8.00195C6.89695 9 6.00195 9.895 6.00195 11V12.725"
          stroke={color}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_4566_68277">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default SvgLock;
