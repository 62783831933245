import { IIconProps } from '../interface';

const SvgMyIconsTravelEarthWorldDirection = ({
  width = 24,
  height = 24,
  color = '#323232',
}: IIconProps) => {
  return (
    <svg
      width={width}
      height={height}
      version="1.1"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g
        strokeLinecap="round"
        strokeWidth="1.5"
        stroke={color}
        fill="none"
        strokeLinejoin="round"
      >
        <path d="M16.459,21.431l-1.996,-6.376c-0.211,-0.673 0.478,-1.275 1.117,-0.977l5.953,2.778c0.706,0.329 0.587,1.367 -0.175,1.528l-2.451,0.519l-0.91,2.557c-0.261,0.734 -1.306,0.714 -1.538,-0.029Z"></path>
        <path d="M20,11h-4.172c-1.562,0 -2.828,-1.266 -2.828,-2.828v0c0,-0.75 0.298,-1.47 0.828,-2l2.454,-2.454"></path>
        <path d="M4.708,17.438l1.777,-2.665c0.322,-0.483 0.863,-0.773 1.443,-0.773v0c0.657,0 1.257,-0.371 1.551,-0.959l0.133,-0.266c0.244,-0.488 0.244,-1.063 0,-1.551l-1.133,-2.265c-0.293,-0.588 -0.894,-0.959 -1.551,-0.959h-4.408"></path>
        <path d="M11,2c-4.982,0 -9,4.018 -9,9c0,4.982 4.018,9 9,9"></path>
        <path d="M20,11c0,-4.982 -4.018,-9 -9,-9"></path>
      </g>
      <path fill="none" d="M0,0h24v24h-24Z"></path>
    </svg>
  );
};

export default SvgMyIconsTravelEarthWorldDirection;
