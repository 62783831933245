import { IIconProps } from '../interface';

const SvgCroatia = ({ width = 56, height = 56, type }: IIconProps) => {
  if (type === 'stripe') {
    return (
      <svg
        width={width}
        height={height}
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 16 16"
      >
        <g fill="none" fill-rule="evenodd">
          <path
            fill="#E25950"
            d="M1.994 2A1.992 1.992 0 0 0 0 4v2h16V4c0-1.105-.895-2-1.994-2H1.994Z"
          ></path>
          <path
            fill="#43458B"
            d="M0 10v2c0 1.105.895 2 1.994 2h12.012A1.992 1.992 0 0 0 16 12v-2H0Z"
          ></path>
          <path fill="#F6F9FC" d="M0 6h16v4H0z"></path>
          <path
            fill="#EAEEF3"
            fill-rule="nonzero"
            d="M6 6h4v2.5a2 2 0 1 1-4 0V6Z"
          ></path>
          <path
            fill="#E25950"
            fill-rule="nonzero"
            d="M7 7H6V6h1v1Zm2 0H8V6h1v1ZM8 8H7V7h1v1Zm2 0H9V7h1v1ZM7 9H6V8h1v1Zm2 0H8V8h1v1Zm-1 1H7V9h1v1Zm-1 .232A2.006 2.006 0 0 1 6.677 10H7v.232Zm2 0a1.99 1.99 0 0 1-1 .268V10h1v.232ZM9.937 9a2 2 0 0 1-.614 1H9V9h.937Z"
          ></path>
          <path
            fill="#EAEEF3"
            fill-rule="nonzero"
            d="M15 10V6h1v4h-1ZM0 6h1v4H0V6Z"
          ></path>
        </g>
      </svg>
    );
  }

  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 56 56"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M56 28C56 24.575 55.3839 21.2941 54.2584 18.2609L28 17.0435L1.74158 18.2608C0.616109 21.2941 0 24.575 0 28C0 31.425 0.616109 34.7059 1.74158 37.7391L28 38.9565L54.2584 37.7392C55.3839 34.7059 56 31.425 56 28Z"
        fill="#F0F0F0"
      />
      <path
        d="M28 56C40.039 56 50.3022 48.4015 54.2584 37.7391H1.74158C5.69778 48.4015 15.961 56 28 56Z"
        fill="#0052B4"
      />
      <path
        d="M1.74158 18.2609H54.2585C50.3022 7.5985 40.039 0 28 0C15.961 0 5.69778 7.5985 1.74158 18.2609Z"
        fill="#D80027"
      />
      <path
        d="M35.3043 19.4784H30.4347L31.2464 13.3913L34.4926 10.9565L37.739 13.3913V18.2609L35.3043 19.4784Z"
        fill="#338AF3"
      />
      <path
        d="M20.6956 19.4784H25.5651L24.7535 13.3913L21.5071 10.9565L18.2609 13.3913V18.2609L20.6956 19.4784Z"
        fill="#338AF3"
      />
      <path
        d="M31.2465 19.4784H24.7535V13.3913L28 10.9565L31.2465 13.3913V19.4784Z"
        fill="#0052B4"
      />
      <path
        d="M18.2609 18.261V31.6522C18.2609 34.8409 19.8011 37.6765 22.1767 39.4542L23.8811 39.0277L26.0318 41.1914C26.6679 41.3224 27.326 41.3914 28.0001 41.3914C28.6705 41.3914 29.3253 41.3233 29.958 41.1936L32.4131 39.1494L33.8232 39.4502C36.1978 37.6727 37.7391 34.84 37.7391 31.6522V18.261H18.2609Z"
        fill="#F0F0F0"
      />
      <path
        d="M18.2609 18.2609H22.1565V22.1565H18.2609V18.2609Z"
        fill="#D80027"
      />
      <path
        d="M26.0521 18.2609H29.9477V22.1565H26.0521V18.2609Z"
        fill="#D80027"
      />
      <path
        d="M33.8434 18.2609H37.739V22.1565H33.8434V18.2609Z"
        fill="#D80027"
      />
      <path
        d="M22.1565 22.1565H26.0521V26.0521H22.1565V22.1565Z"
        fill="#D80027"
      />
      <path
        d="M29.9478 22.1565H33.8434V26.0521H29.9478V22.1565Z"
        fill="#D80027"
      />
      <path
        d="M18.2609 26.0509H22.1565V29.9465H18.2609V26.0509Z"
        fill="#D80027"
      />
      <path
        d="M22.1565 29.9467H26.0521V33.8423H22.1565V29.9467Z"
        fill="#D80027"
      />
      <path
        d="M26.0521 26.0509H29.9477V29.9465H26.0521V26.0509Z"
        fill="#D80027"
      />
      <path
        d="M33.8434 26.0509H37.739V29.9465H33.8434V26.0509Z"
        fill="#D80027"
      />
      <path
        d="M29.9478 29.9467H33.8434V33.8423H29.9478V29.9467Z"
        fill="#D80027"
      />
      <path
        d="M26.0521 33.8423H29.9477V37.7379H26.0521V33.8423Z"
        fill="#D80027"
      />
      <path
        d="M22.1565 33.8426H18.5122C18.8465 35.2907 19.5036 36.6158 20.4046 37.7382H22.1565V33.8426Z"
        fill="#D80027"
      />
      <path
        d="M33.8434 37.7383H35.5951C36.4964 36.6159 37.1533 35.2907 37.4875 33.8427H33.8434V37.7383Z"
        fill="#D80027"
      />
      <path
        d="M22.1565 37.7383V39.4381C23.2895 40.2907 24.6136 40.902 26.0521 41.1953V37.7383H22.1565Z"
        fill="#D80027"
      />
      <path
        d="M29.9478 37.7383V41.1953C31.3864 40.9022 32.7103 40.2907 33.8434 39.4381V37.7383H29.9478Z"
        fill="#D80027"
      />
    </svg>
  );
};

export default SvgCroatia;
