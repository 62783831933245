import { IIconProps } from '../interface';

const SvgMalta = ({ width = 56, height = 56, type }: IIconProps) => {
  if (type === 'stripe') {
    return (
      <svg
        width={width}
        height={height}
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 16 16"
      >
        <g fill="none" fill-rule="evenodd">
          <path
            fill="#F6F9FC"
            d="M1.998 2A2.005 2.005 0 0 0 0 4.009v7.982C0 13.101.887 14 1.998 14H8V2H1.998Z"
          ></path>
          <path
            fill="#EAEEF3"
            fill-rule="nonzero"
            d="M2 3a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h6v1H2a2 2 0 0 1-2-2V4a2 2 0 0 1 2-2h6v1H2Z"
          ></path>
          <path
            fill="#E25950"
            d="M8 2v12h6.002A2.005 2.005 0 0 0 16 11.991V4.01C16 2.899 15.113 2 14.002 2H8Z"
          ></path>
          <path fill="#CFD7DF" d="M5 5h2v2H5v2H3V7H1V5h2V3h2z"></path>
        </g>
      </svg>
    );
  }

  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 56 56"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M28 56C43.464 56 56 43.464 56 28C56 12.536 43.464 0 28 0C12.536 0 0 12.536 0 28C0 43.464 12.536 56 28 56Z"
        fill="#F0F0F0"
      />
      <path
        d="M28 0C43.4639 0 56 12.5361 56 28C56 43.4639 43.4639 56 28 56"
        fill="#D80027"
      />
      <path
        d="M19.4782 10.9565V7.30438H15.8261V10.9565H12.1738V14.6087H15.8261V18.2609H19.4782V14.6087H23.1304V10.9565H19.4782Z"
        fill="#ACABB1"
      />
    </svg>
  );
};

export default SvgMalta;
