import { IIconProps } from '../interface';

const SvgClose = ({ width = 32, height = 32, color, type }: IIconProps) => {
  switch (type) {
    case 'circle':
      return (
        <svg
          width={width}
          height={height}
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M10.7072 9.29287L16.0001 14.5858L21.293 9.29289L22.7072 10.7071L17.4143 16L22.7071 21.2927L21.2929 22.7069L16.0001 17.4142L10.7071 22.7072L9.29286 21.293L14.5859 16L9.29303 10.7071L10.7072 9.29287Z"
            fill={color}
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M30 16C30 23.732 23.732 30 16 30C8.26801 30 2 23.732 2 16C2 8.26801 8.26801 2 16 2C23.732 2 30 8.26801 30 16ZM28 16C28 22.6274 22.6274 28 16 28C9.37258 28 4 22.6274 4 16C4 9.37258 9.37258 4 16 4C22.6274 4 28 9.37258 28 16Z"
            fill={color}
          />
        </svg>
      );
    case 'md':
      return (
        <svg
          width={width}
          height={height}
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M17.4143 16L28.7072 27.2928L27.293 28.7071L16.0001 17.4142L4.70727 28.7071L3.29305 27.2928L14.5859 16L3.29297 4.70706L4.70718 3.29285L16.0001 14.5858L27.293 3.29285L28.7073 4.70706L17.4143 16Z"
            fill={color}
          />
        </svg>
      );
    case 'sm':
      return (
        <svg
          width={width}
          height={height}
          viewBox="0 0 22 22"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M11.0002 9.58593L3.70722 2.29297L2.293 3.70718L9.58596 11.0001L2.29297 18.2931L3.70718 19.7073L11.0002 12.4144L18.293 19.7072L19.7072 18.293L12.4144 11.0001L19.7072 3.70736L18.293 2.29314L11.0002 9.58593Z"
            fill={color}
            stroke={color}
            strokeWidth="2"
          />
        </svg>
      );
    case 'filled':
      return (
        <svg
          width={width}
          height={height}
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M16 30C23.732 30 30 23.732 30 16C30 8.26801 23.732 2 16 2C8.26801 2 2 8.26801 2 16C2 23.732 8.26801 30 16 30ZM21.2931 9.29285L22.7073 10.7071L17.4144 15.9999L22.7071 21.2927L21.2929 22.7069L16.0002 17.4142L10.7071 22.7072L9.29291 21.293L14.5859 15.9999L9.29307 10.7071L10.7073 9.29285L16.0002 14.5857L21.2931 9.29285Z"
            fill={color}
          />
        </svg>
      );
    default:
      return (
        <svg
          width={width}
          height={height}
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M10.7072 9.29287L16.0001 14.5858L21.293 9.29289L22.7072 10.7071L17.4143 16L22.7071 21.2927L21.2929 22.7069L16.0001 17.4142L10.7071 22.7072L9.29286 21.293L14.5859 16L9.29303 10.7071L10.7072 9.29287Z"
            fill={color}
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M30 16C30 23.732 23.732 30 16 30C8.26801 30 2 23.732 2 16C2 8.26801 8.26801 2 16 2C23.732 2 30 8.26801 30 16ZM28 16C28 22.6274 22.6274 28 16 28C9.37258 28 4 22.6274 4 16C4 9.37258 9.37258 4 16 4C22.6274 4 28 9.37258 28 16Z"
            fill={color}
          />
        </svg>
      );
  }
};

export default SvgClose;
