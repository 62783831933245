import { IIconProps } from '../interface';

const SvgDownload = ({ width = 56, height = 56, type }: IIconProps) => {
  if (type === 'filled') {
    return (
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clipPath="url(#clip0_5558_17066)">
          <path
            d="M18.3643 5.63576C21.8793 9.15076 21.8793 14.8488 18.3643 18.3638C14.8493 21.8788 9.15125 21.8788 5.63625 18.3638C2.12125 14.8488 2.12125 9.15076 5.63625 5.63576C9.15125 2.12076 14.8493 2.12076 18.3643 5.63576Z"
            stroke="#323232"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M18.3643 5.63576C21.8793 9.15076 21.8793 14.8488 18.3643 18.3638C14.8493 21.8788 9.15125 21.8788 5.63625 18.3638C2.12125 14.8488 2.12125 9.15076 5.63625 5.63576C9.15125 2.12076 14.8493 2.12076 18.3643 5.63576Z"
            stroke="#323232"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M12 7V14"
            stroke="#323232"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M14.0246 11.9746L11.9996 13.9996L9.97461 11.9746"
            stroke="#323232"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M8.46484 15.5356C10.4178 17.4886 13.5828 17.4886 15.5358 15.5356"
            stroke="#323232"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </g>
        <defs>
          <clipPath id="clip0_5558_17066">
            <rect width="24" height="24" fill="white" />
          </clipPath>
        </defs>
      </svg>
    );
  }

  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 56 56"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_5558_16191)">
        <circle cx="28" cy="28" r="21" fill="#EBEDFA" />
        <path
          d="M42.8499 13.1508C51.0516 21.3524 51.0516 34.6478 42.8499 42.8494C34.6482 51.0511 21.3529 51.0511 13.1512 42.8494C4.94958 34.6478 4.94958 21.3524 13.1512 13.1508C21.3529 4.94909 34.6482 4.94909 42.8499 13.1508Z"
          stroke="#0A1899"
          strokeWidth="2.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M42.8499 13.1508C51.0516 21.3524 51.0516 34.6478 42.8499 42.8494C34.6482 51.0511 21.3529 51.0511 13.1512 42.8494C4.94958 34.6478 4.94958 21.3524 13.1512 13.1508C21.3529 4.94909 34.6482 4.94909 42.8499 13.1508Z"
          stroke="#0A1899"
          strokeWidth="2.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M28 16.3335V32.6668"
          stroke="#0A1899"
          strokeWidth="2.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M32.7254 27.9419L28.0004 32.6669L23.2754 27.9419"
          stroke="#0A1899"
          strokeWidth="2.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M19.75 36.251C24.307 40.808 31.692 40.808 36.249 36.251"
          stroke="#0A1899"
          strokeWidth="2.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_5558_16191">
          <rect width="56" height="56" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default SvgDownload;
