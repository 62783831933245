import { useEffect } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import ReactGA from 'react-ga4';

const GAPageView = () => {
  const location = useLocation();

  useEffect(() => {
    ReactGA.set({ page: window.location.pathname });
    ReactGA.send({ hitType: 'pageview', page: window.location.pathname });
  }, [location]);

  return <Outlet />;
};

export default GAPageView;
