const languages: { [key: string]: any } = {
  DE: 'German',
  NL: 'Dutch',
  BG: 'Bulgarian',
  HU: 'Hungarian',
  EL: 'Greek',
  CS: 'Czech',
  DA: 'Danish',
  ET: 'Estonian',
  FI: 'Finnish',
  FR: 'French',
  HR: 'Croatian',
  TR: 'Turkish',
  SR: 'Serbian',
  EN: 'English',
  IT: 'Italian',
  LV: 'Latvian',
  LT: 'Lithuanian',
  BS: 'Bosnian',
  MT: 'Maltese',
  CA: 'Catalan',
  PL: 'Polish',
  PT: 'Portuguese',
  RO: 'Romanian',
  SK: 'Slovak',
  SL: 'Slovenian',
  ES: 'Spanish',
  SV: 'Swedish',
  NO: 'Norwegian',
  ZH: 'Chinese',
  JA: 'Japanese',
  TH: 'Thai',
  BN: 'Bengali',
  HI: 'Hindi',
  KO: 'Korean',
  VI: 'Vietnamese',
  TE: 'Telugu',
  RU: 'Russian',
  BE: 'Belarusian',
  SQ: 'Albanian',
  UK: 'Ukranian',
  IS: 'Icelandic',
  MS: 'Malay',
  ID: 'Indonesian',
  TL: 'Tagalog',
  AR: 'Arabic',
  AF: 'Afrikaans',
  SW: 'Swahili',
  EO: 'Esperanto',
};

export default languages;
