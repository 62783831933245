import { useEffect, useState } from 'react';
import Icon from '../../design-system/Icon';
import { useNavigate } from 'react-router-dom';
import { IToolAnchorMenu } from '../consts';
import { Anchor, Grid, ToolAnchor, Typography } from '../../design-system';
import Collapse from '../collapse';
import { useTranslation } from 'react-i18next';
import { changeQuestionById } from '../../reducers/trtReducer';
import useSidebar from './hooks/useSidebar';
import { useDispatch, useSelector } from 'react-redux';
import { User } from '@supabase/supabase-js';
import { supabase } from '../../supabaseClient';
import LanguageSelector from '../languageSelector';
import { addBreadcrumb, AppsCategoryEnum, Level } from '../../utils/sentry';
import { IRootState } from '../../store';
import getUrlParameters from '../../utils/getUrlParameters';

interface ISidebar {
  variant: 'APP' | 'TOOL';
  short?: boolean;
}

const Sidebar = ({ variant, short }: ISidebar) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { sidebar } = useSidebar();
  const [user, setUser] = useState<User | null>({} as User);
  const [openMenu, setOpenMenu] = useState<boolean>(false);
  const navigate = useNavigate();

  const { role } = useSelector((state: IRootState) => state.advice);

  /*
   *
   * Disabled menus for all roles
   */
  // const role = localStorage.getItem('user_role');

  const { fiscalYear: year } = useSelector(
    (state: IRootState) => state.tat
  );

  const fiscalYear =
    getUrlParameters('y') || year || localStorage.getItem('year');

  useEffect(() => {
    const handleSession = async () => {
      const sessionData = await supabase.auth.getSession();
      setUser(sessionData?.data.session?.user ?? null);
    };

    handleSession();
  }, []);

  const goToQuestions = ({
    anchorId,
    questionId,
  }: {
    anchorId: number | string;
    questionId: number | string;
  }) => {
    setOpenMenu(false);
    dispatch(
      changeQuestionById({
        category_id: anchorId,
        question_id: questionId,
      })
    );

    addBreadcrumb({
      category: AppsCategoryEnum.TOOL,
      message: 'Click sidebar - change trt question',
      level: Level.Info,
      data: {
        category_id: anchorId,
        question_id: questionId,
      },
    });
  };

  const GoBackButton = ({ className }: { className?: string }) => {
    if (!!user && window.location.pathname === '/trt') {
      return (
        <div className="absolute left-[100%] ml-spacing-36 msm:left-spacing-90">
          <button
            onClick={() => navigate(`/taxyear?y=${fiscalYear}`)}
            className={`flex flex-row items-center cursor-pointer ${className}`}
          >
            <Icon name="ArrowLeft" width={12} height={10} color={'#777A83'} />
            <Typography
              variant="LabelSmall500"
              className="ml-spacing-10 whitespace-nowrap"
              color="gray500"
            >
              {t('header.back_overview', { fiscalYear })}
            </Typography>
          </button>
        </div>
      );
    }

    return null;
  };

  return (
    <>
      <div
        className={`fixed flex flex-col justify-between z-20 msm:pt-spacing-10 md:pt-spacing-32 pb-spacing-4 msm:w-full md:h-screen ${
          short ? 'md:w-[64px]' : 'md:w-spacing-270 '
        } ${variant === 'APP' ? 'bg-base-gray25' : 'bg-white'}`}
      >
        <div
          className={`flex items-center justify-between md:mb-spacing-26 msm:mb-spacing-10 ${
            short ? 'ml-[30px]' : 'ml-spacing-32'
          }`}
        >
          <div className="md:hidden">
            <button onClick={() => setOpenMenu(!openMenu)}>
              <Icon name="BurgerMenu" />
            </button>
          </div>
          <a href={!!user ? '/' : 'https://tytle.io/'}>
            <Icon
              name={`${short ? 'ShortLogo' : 'Logo'}`}
              width={short ? 19 : 91}
              height={short ? 19 : 35}
            />
          </a>
          <GoBackButton />
          {/**
           * REMOVE THE HIDDEN CLASS TO SHOW COUNTRY SELECTOR
           */}
          <div className="md:fixed md:right-0 md:top-spacing-20 hidden">
            <LanguageSelector />
          </div>
        </div>
        <div
          className={`flex flex-1 flex-col ${
            variant === 'APP' ? 'bg-base-gray25 flex-1' : 'bg-white'
          } msm:fixed msm:w-3/4 msm:z-20 msm:bg-white msm:h-screen msm:top-spacing-48 msm:pt-spacing-20 msm:transition-all msm:overflow-auto ${
            openMenu ? 'msm:left-spacing-0' : 'msm:-left-spacing-610'
          }`}
        >
          {variant === 'APP' ? (
            <>
              <div className="flex-1 ml-spacing-16">
                {/*
                 *
                 * Disabled menus for all roles
                 */}
                {/* {role !== 'advisor' ? sidebarAppAnchors.map((anchor) => (
                  <Anchor
                    {...anchor}
                    label={anchor.label}
                    key={anchor.label}
                    className={`mb-spacing-8 pl-spacing-16`}
                    short={short}
                  />
                )) : <></>} */}
              </div>
              <div className={`${short && 'hidden'}`}>
                <Grid
                  noPadding
                  fluid
                  onClick={() => {
                    localStorage.clear();
                    supabase.auth.signOut().catch(console.error);
                    window.location.href = '/login';
                  }}
                  className="px-spacing-18 my-spacing-20 cursor-pointer"
                >
                  <Icon name="Leave" />
                </Grid>
                <Anchor
                  label={''}
                  description={user?.email}
                  path={role === 'advisor' ? '/partner/info' : "/#"}
                  className="my-spacing-16 !bg-base-gray25"
                />
              </div>
            </>
          ) : (
            <>
              <div className="flex-1">
                {sidebar.map((anchor: IToolAnchorMenu, index) => {
                  const anchorProps = {
                    ...anchor,
                    label: t(anchor.label),
                  };

                  return (
                    <Collapse
                      header={<ToolAnchor {...anchorProps} />}
                      disabled={anchor.disabled}
                      className={
                        !anchor.done && !anchor.disabled
                          ? 'py-spacing-16'
                          : null
                      }
                      active={anchor.opened}
                      key={anchor.label + index}
                    >
                      <div className="ml-spacing-16">
                        {anchor.questions?.map((question) => {
                          const questionProps = {
                            ...question,
                            label: t(question.label),
                            action: () =>
                              goToQuestions({
                                anchorId: anchor.id,
                                questionId: question.id,
                              }),
                          };

                          return (
                            <ToolAnchor
                              key={question.label}
                              {...questionProps}
                            />
                          );
                        })}
                      </div>
                    </Collapse>
                  );
                })}
              </div>
            </>
          )}
        </div>
      </div>
      <div
        className={`fixed bg-black w-screen h-screen top-spacing-48 transition-all left-0 ${
          openMenu ? 'opacity-70 z-10' : 'opacity-0 -z-50'
        }`}
        onClick={() => setOpenMenu(!openMenu)}
      ></div>
    </>
  );
};

export default Sidebar;
