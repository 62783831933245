import { IIconProps } from '../interface';

const SvgMyIconsSuitcaseFinancialPortfolio = ({
  width = 24,
  height = 24,
  color = '#323232',
}: IIconProps) => {
  return (
    <svg
      width={width}
      height={height}
      version="1.1"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g fill="none">
        <path d="M0,0h24v24h-24v-24Z"></path>
        <path
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5"
          d="M12,11v-1"
        ></path>
        <path
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5"
          d="M12,17v1"
        ></path>
        <path
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5"
          d="M10.264,16.285c0.259,0.424 0.701,0.717 1.236,0.717h0.5h0.596c0.776,0 1.404,-0.629 1.404,-1.404c0,-0.644 -0.439,-1.205 -1.063,-1.363l-1.875,-0.471c-0.623,-0.156 -1.062,-0.717 -1.062,-1.361c0,-0.776 0.629,-1.404 1.404,-1.404h0.596h0.5c0.533,0 0.975,0.292 1.233,0.715"
        ></path>
        <path
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5"
          d="M19,20.5h-14c-1.105,0 -2,-0.895 -2,-2v-9c0,-1.105 0.895,-2 2,-2h14c1.105,0 2,0.895 2,2v9c0,1.105 -0.895,2 -2,2Z"
        ></path>
        <path
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5"
          d="M16.174,7.5v-2c0,-1.105 -0.895,-2 -2,-2h-4.348c-1.105,0 -2,0.895 -2,2v2"
        ></path>
      </g>
    </svg>
  );
};

export default SvgMyIconsSuitcaseFinancialPortfolio;
