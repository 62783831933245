import { SpinnerLoading } from '../../design-system';

const Loading = ({ variant }: { variant: 'FULL' | 'APP' }) => {
  const styles = variant === 'APP' ? { zIndex: 10 } : { zIndex: 30 };

  return (
    <div
      className="flex fixed top-0 left-0 w-full h-full items-center justify-center bg-base-gray25"
      style={styles}
    >
      <SpinnerLoading
        variant="secondary"
        active={true}
        width={60}
        height={60}
      />
    </div>
  );
};

export default Loading;
