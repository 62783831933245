import { IIconProps } from '../interface';

const SvgPoland = ({ width = 56, height = 56, type }: IIconProps) => {
  switch (type) {
    case 'stripe':
      console.warn('Invalid option for this icon');
      return (
        <svg
          width={width}
          height={height}
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 16 16"
        >
          <g fill="none" fill-rule="evenodd">
            <path
              fill="#F6F9FC"
              d="M1.994 2A1.998 1.998 0 0 0 0 3.998V8h16V3.998A1.997 1.997 0 0 0 14.006 2H1.994Z"
            ></path>
            <path
              fill="#EAEEF3"
              fill-rule="nonzero"
              d="M14 2a2 2 0 0 1 2 2v4h-1V4a1 1 0 0 0-1-1H2a1 1 0 0 0-1 1v4H0V4a2 2 0 0 1 2-2h12Z"
            ></path>
            <path
              fill="#E25950"
              d="M0 8v4.002C0 13.106.895 14 1.994 14h12.012A1.998 1.998 0 0 0 16 12.002V8H0Z"
            ></path>
          </g>
        </svg>
      );
    default:
      return (
        <svg
          width={width}
          height={height}
          viewBox="0 0 56 56"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M28 56C43.464 56 56 43.464 56 28C56 12.536 43.464 0 28 0C12.536 0 0 12.536 0 28C0 43.464 12.536 56 28 56Z"
            fill="#F0F0F0"
          />
          <path
            d="M56 28C56 43.4639 43.4639 56 28 56C12.5361 56 0 43.4639 0 28"
            fill="#D80027"
          />
        </svg>
      );
  }
};

export default SvgPoland;
