import { IIconProps } from '../interface';

const SvgGermany = ({ width = 56, height = 56, type }: IIconProps) => {
  if (type === 'stripe') {
    return (
      <svg
        width={width}
        height={height}
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 16 16"
      >
        <g fill="none">
          <path fill="#E25950" d="M0 6h16v4H0z"></path>
          <path
            fill="#FCD669"
            d="M0 12.4V10h16v2.4c0 .884-.682 1.6-1.524 1.6H1.524C.682 14 0 13.284 0 12.4Z"
          ></path>
          <path
            fill="#A68527"
            fill-opacity="0.1"
            d="M15 10v2a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1v-2H0v2c0 1.392.476 2 2 2h12c1.579 0 2-.733 2-2v-2h-1Z"
          ></path>
          <path
            fill="#333"
            d="M16 3.6V6H0V3.6C0 2.716.682 2 1.524 2h12.952C15.318 2 16 2.716 16 3.6Z"
          ></path>
        </g>
      </svg>
    );
  }

  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 56 56"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.74158 37.7391C5.69778 48.4014 15.961 56 28 56C40.039 56 50.3022 48.4014 54.2584 37.7391L28 35.3044L1.74158 37.7391Z"
        fill="#FFDA44"
      />
      <path
        d="M28 0C15.961 0 5.69778 7.5985 1.74158 18.2609L28 20.6956L54.2584 18.2608C50.3022 7.5985 40.039 0 28 0Z"
        fill="black"
      />
      <path
        d="M1.74158 18.2609C0.616109 21.2942 0 24.575 0 28C0 31.425 0.616109 34.7058 1.74158 37.7391H54.2585C55.384 34.7058 56 31.425 56 28C56 24.575 55.384 21.2942 54.2584 18.2609H1.74158Z"
        fill="#D80027"
      />
    </svg>
  );
};

export default SvgGermany;
