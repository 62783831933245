import env from '../../../env';

export enum FlagsListEnum {
  EastTimor = 'EastTimor',
  RapaNui = 'RapaNui',
  Abkhazia = 'Abkhazia',
  Afghanistan = 'Afghanistan',
  AlandIslands = 'AlandIslands',
  Albania = 'Albania',
  Algeria = 'Algeria',
  AmericanSamoa = 'AmericanSamoa',
  Andorra = 'Andorra',
  Angola = 'Angola',
  Anguilla = 'Anguilla',
  AntiguaAndBarbuda = 'AntiguaAndBarbuda',
  Argentina = 'Argentina',
  Armenia = 'Armenia',
  Aruba = 'Aruba',
  Australia = 'Australia',
  Austria = 'Austria',
  Azerbaijan = 'Azerbaijan',
  AzoresIslands = 'AzoresIslands',
  Bahamas = 'Bahamas',
  Bahrain = 'Bahrain',
  BalearicIslands = 'BalearicIslands',
  Bangladesh = 'Bangladesh',
  Barbados = 'Barbados',
  BasqueCountry = 'BasqueCountry',
  Belarus = 'Belarus',
  Belgium = 'Belgium',
  Belize = 'Belize',
  Benin = 'Benin',
  Bermuda = 'Bermuda',
  Bhutan = 'Bhutan',
  Bolivia = 'Bolivia',
  Bonaire = 'Bonaire',
  BosniaAndHerzegovina = 'BosniaAndHerzegovina',
  Botswana = 'Botswana',
  Brazil = 'Brazil',
  BritishColumbia = 'BritishColumbia',
  BritishIndianOceanTerritory = 'BritishIndianOceanTerritory',
  BritishVirginIslands = 'BritishVirginIslands',
  Brunei = 'Brunei',
  Bulgaria = 'Bulgaria',
  BurkinaFaso = 'BurkinaFaso',
  Burundi = 'Burundi',
  Cambodia = 'Cambodia',
  Cameroon = 'Cameroon',
  Canada = 'Canada',
  CanaryIslands = 'CanaryIslands',
  CapeVerde = 'CapeVerde',
  CaymanIslands = 'CaymanIslands',
  CentralAfricanRepublic = 'CentralAfricanRepublic',
  Ceuta = 'Ceuta',
  Chad = 'Chad',
  Chile = 'Chile',
  China = 'China',
  CocosIsland = 'CocosIsland',
  Colombia = 'Colombia',
  Comoros = 'Comoros',
  CookIslands = 'CookIslands',
  Corsica = 'Corsica',
  CostaRica = 'CostaRica',
  Croatia = 'Croatia',
  Cuba = 'Cuba',
  Curacao = 'Curacao',
  Cyprus = 'Cyprus',
  CzechRepublic = 'CzechRepublic',
  DemocraticRepublicOfCongo = 'DemocraticRepublicOfCongo',
  Denmark = 'Denmark',
  Djibouti = 'Djibouti',
  Dominica = 'Dominica',
  DominicanRepublic = 'DominicanRepublic',
  Ecuador = 'Ecuador',
  Egypt = 'Egypt',
  ElSalvador = 'ElSalvador',
  England = 'England',
  EquatorialGuinea = 'EquatorialGuinea',
  Eritrea = 'Eritrea',
  Estonia = 'Estonia',
  Ethiopia = 'Ethiopia',
  EuropeanUnion = 'EuropeanUnion',
  FalklandIslands = 'FalklandIslands',
  FaroeIslands = 'FaroeIslands',
  Fiji = 'Fiji',
  Finland = 'Finland',
  France = 'France',
  FrenchPolynesia = 'FrenchPolynesia',
  Gabon = 'Gabon',
  GalapagosIslands = 'GalapagosIslands',
  Gambia = 'Gambia',
  Georgia = 'Georgia',
  Germany = 'Germany',
  Ghana = 'Ghana',
  Gibraltar = 'Gibraltar',
  Greece = 'Greece',
  Greenland = 'Greenland',
  Grenada = 'Grenada',
  Guam = 'Guam',
  Guatemala = 'Guatemala',
  Guernsey = 'Guernsey',
  GuineaBissau = 'GuineaBissau',
  Guinea = 'Guinea',
  Guyana = 'Guyana',
  Haiti = 'Haiti',
  Hawaii = 'Hawaii',
  Honduras = 'Honduras',
  HongKong = 'HongKong',
  Hungary = 'Hungary',
  Iceland = 'Iceland',
  India = 'India',
  Indonesia = 'Indonesia',
  Iran = 'Iran',
  Iraq = 'Iraq',
  Ireland = 'Ireland',
  IsleOfMan = 'IsleOfMan',
  Israel = 'Israel',
  Italy = 'Italy',
  IvoryCoast = 'IvoryCoast',
  Jamaica = 'Jamaica',
  Japan = 'Japan',
  Jersey = 'Jersey',
  Jordan = 'Jordan',
  Kazakhstan = 'Kazakhstan',
  Kenya = 'Kenya',
  Kiribati = 'Kiribati',
  Kosovo = 'Kosovo',
  Kuwait = 'Kuwait',
  Kyrgyzstan = 'Kyrgyzstan',
  Laos = 'Laos',
  Latvia = 'Latvia',
  Lebanon = 'Lebanon',
  Lesotho = 'Lesotho',
  Liberia = 'Liberia',
  Libya = 'Libya',
  Liechtenstein = 'Liechtenstein',
  Lithuania = 'Lithuania',
  Luxembourg = 'Luxembourg',
  Macao = 'Macao',
  Madagascar = 'Madagascar',
  Madeira = 'Madeira',
  Malawi = 'Malawi',
  Malaysia = 'Malaysia',
  Maldives = 'Maldives',
  Mali = 'Mali',
  Malta = 'Malta',
  MarshallIsland = 'MarshallIsland',
  Martinique = 'Martinique',
  Mauritania = 'Mauritania',
  Mauritius = 'Mauritius',
  Melilla = 'Melilla',
  Mexico = 'Mexico',
  Micronesia = 'Micronesia',
  Moldova = 'Moldova',
  Monaco = 'Monaco',
  Mongolia = 'Mongolia',
  Montenegro = 'Montenegro',
  Montserrat = 'Montserrat',
  Morocco = 'Morocco',
  Mozambique = 'Mozambique',
  Myanmar = 'Myanmar',
  Namibia = 'Namibia',
  Nato = 'Nato',
  Nauru = 'Nauru',
  Nepal = 'Nepal',
  Netherlands = 'Netherlands',
  NewZealand = 'NewZealand',
  Nicaragua = 'Nicaragua',
  Niger = 'Niger',
  Nigeria = 'Nigeria',
  Niue = 'Niue',
  NorfolkIsland = 'NorfolkIsland',
  NorthKorea = 'NorthKorea',
  NorthernCyprus = 'NorthernCyprus',
  NorthernMarianasIslands = 'NorthernMarianasIslands',
  Norway = 'Norway',
  Oman = 'Oman',
  OrkneyIslands = 'OrkneyIslands',
  Ossetia = 'Ossetia',
  Pakistan = 'Pakistan',
  Palau = 'Palau',
  Palestine = 'Palestine',
  Panama = 'Panama',
  PapuaNewGuinea = 'PapuaNewGuinea',
  Paraguay = 'Paraguay',
  Peru = 'Peru',
  Philippines = 'Philippines',
  PitcairnIslands = 'PitcairnIslands',
  Poland = 'Poland',
  Portugal = 'Portugal',
  PuertoRico = 'PuertoRico',
  Qatar = 'Qatar',
  RepublicOfMacedonia = 'RepublicOfMacedonia',
  RepublicOfTheCongo = 'RepublicOfTheCongo',
  Romania = 'Romania',
  Russia = 'Russia',
  Rwanda = 'Rwanda',
  SabaIsland = 'SabaIsland',
  SahrawiArabDemocraticRepublic = 'SahrawiArabDemocraticRepublic',
  Samoa = 'Samoa',
  SanMarino = 'SanMarino',
  SaoTomeAndPrince = 'SaoTomeAndPrince',
  Sardinia = 'Sardinia',
  SaudiArabia = 'SaudiArabia',
  Scotland = 'Scotland',
  Senegal = 'Senegal',
  Serbia = 'Serbia',
  Seychelles = 'Seychelles',
  SierraLeone = 'SierraLeone',
  Singapore = 'Singapore',
  SintEustatius = 'SintEustatius',
  SintMaarten = 'SintMaarten',
  Slovakia = 'Slovakia',
  Slovenia = 'Slovenia',
  SolomonIslands = 'SolomonIslands',
  Somalia = 'Somalia',
  Somaliland = 'Somaliland',
  SouthAfrica = 'SouthAfrica',
  SouthKorea = 'SouthKorea',
  SouthSudan = 'SouthSudan',
  Spain = 'Spain',
  SriLanka = 'SriLanka',
  StBarts = 'StBarts',
  StLucia = 'StLucia',
  StVincentAndTheGrenadines = 'StVincentAndTheGrenadines',
  Sudan = 'Sudan',
  Suriname = 'Suriname',
  Swaziland = 'Swaziland',
  Sweden = 'Sweden',
  Switzerland = 'Switzerland',
  Syria = 'Syria',
  Taiwan = 'Taiwan',
  Tajikistan = 'Tajikistan',
  Tanzania = 'Tanzania',
  Thailand = 'Thailand',
  Tibet = 'Tibet',
  Togo = 'Togo',
  Tokelau = 'Tokelau',
  Tonga = 'Tonga',
  Transnistria = 'Transnistria',
  TrinidadAndTobago = 'TrinidadAndTobago',
  Tunisia = 'Tunisia',
  Turkey = 'Turkey',
  Turkmenistan = 'Turkmenistan',
  TurksAndCaicos = 'TurksAndCaicos',
  Tuvalu = 'Tuvalu',
  Uganda = 'Uganda',
  Ukraine = 'Ukraine',
  UnitedArabEmirates = 'UnitedArabEmirates',
  UnitedKingdom = 'UnitedKingdom',
  UnitedNations = 'UnitedNations',
  UnitedStates = 'UnitedStates',
  Uruguay = 'Uruguay',
  Uzbekistán = 'Uzbekistán',
  Vanuatu = 'Vanuatu',
  VaticanCity = 'VaticanCity',
  Venezuela = 'Venezuela',
  Vietnam = 'Vietnam',
  VirginIslands = 'VirginIslands',
  Wales = 'Wales',
  Yemen = 'Yemen',
  Zambia = 'Zambia',
  Zimbabwe = 'Zimbabwe',
  EmptyFlagIcon = 'EmptyFlagIcon',
}

export const FlagsListInitials: { [key: string]: FlagsList } = {
  ...(['development', 'staging'].includes(env.APP_ENV!)
    ? {
        AA: FlagsListEnum.EmptyFlagIcon,
        QQ: FlagsListEnum.EmptyFlagIcon,
        ZZ: FlagsListEnum.EmptyFlagIcon,
      }
    : {}),
  AW: FlagsListEnum.Aruba,
  AF: FlagsListEnum.Afghanistan,
  AO: FlagsListEnum.Angola,
  AI: FlagsListEnum.Anguilla,
  AX: FlagsListEnum.AlandIslands,
  AL: FlagsListEnum.Albania,
  AD: FlagsListEnum.Andorra,
  AE: FlagsListEnum.UnitedArabEmirates,
  AR: FlagsListEnum.Argentina,
  AM: FlagsListEnum.Armenia,
  AS: FlagsListEnum.AmericanSamoa,
  AQ: FlagsListEnum.EmptyFlagIcon,
  TF: FlagsListEnum.EmptyFlagIcon,
  AG: FlagsListEnum.AntiguaAndBarbuda,
  AU: FlagsListEnum.Australia,
  AT: FlagsListEnum.Austria,
  AZ: FlagsListEnum.Azerbaijan,
  BI: FlagsListEnum.Burundi,
  BE: FlagsListEnum.Belgium,
  BJ: FlagsListEnum.Benin,
  BQ: FlagsListEnum.Bonaire,
  BF: FlagsListEnum.BurkinaFaso,
  BD: FlagsListEnum.Bangladesh,
  BG: FlagsListEnum.Bulgaria,
  BH: FlagsListEnum.Bahrain,
  BS: FlagsListEnum.Bahamas,
  BA: FlagsListEnum.BosniaAndHerzegovina,
  BL: FlagsListEnum.StBarts,
  BY: FlagsListEnum.Belarus,
  BZ: FlagsListEnum.Belize,
  BM: FlagsListEnum.Bermuda,
  BO: FlagsListEnum.Bolivia,
  BR: FlagsListEnum.Brazil,
  BB: FlagsListEnum.Barbados,
  BN: FlagsListEnum.Brunei,
  BT: FlagsListEnum.Bhutan,
  BV: FlagsListEnum.EmptyFlagIcon,
  BW: FlagsListEnum.Botswana,
  CF: FlagsListEnum.CentralAfricanRepublic,
  CA: FlagsListEnum.Canada,
  CC: FlagsListEnum.CocosIsland,
  CH: FlagsListEnum.Switzerland,
  CL: FlagsListEnum.Chile,
  CN: FlagsListEnum.China,
  CI: FlagsListEnum.EmptyFlagIcon,
  CM: FlagsListEnum.Cameroon,
  CD: FlagsListEnum.DemocraticRepublicOfCongo,
  CG: FlagsListEnum.EmptyFlagIcon,
  CK: FlagsListEnum.CookIslands,
  CO: FlagsListEnum.Colombia,
  KM: FlagsListEnum.Comoros,
  CV: FlagsListEnum.CapeVerde,
  CR: FlagsListEnum.CostaRica,
  CU: FlagsListEnum.Cuba,
  CW: FlagsListEnum.Curacao,
  CX: FlagsListEnum.EmptyFlagIcon,
  KY: FlagsListEnum.CaymanIslands,
  CY: FlagsListEnum.Cyprus,
  CZ: FlagsListEnum.CzechRepublic,
  DE: FlagsListEnum.Germany,
  DJ: FlagsListEnum.Djibouti,
  DM: FlagsListEnum.Dominica,
  DK: FlagsListEnum.Denmark,
  DO: FlagsListEnum.DominicanRepublic,
  DZ: FlagsListEnum.Algeria,
  EC: FlagsListEnum.Ecuador,
  EG: FlagsListEnum.Egypt,
  ER: FlagsListEnum.Eritrea,
  EH: FlagsListEnum.EmptyFlagIcon,
  ES: FlagsListEnum.Spain,
  EE: FlagsListEnum.Estonia,
  ET: FlagsListEnum.Ethiopia,
  FI: FlagsListEnum.Finland,
  FJ: FlagsListEnum.Fiji,
  FK: FlagsListEnum.FalklandIslands,
  FR: FlagsListEnum.France,
  FO: FlagsListEnum.FaroeIslands,
  FM: FlagsListEnum.Micronesia,
  GA: FlagsListEnum.Gabon,
  GB: FlagsListEnum.UnitedKingdom,
  GE: FlagsListEnum.Georgia,
  GG: FlagsListEnum.Guernsey,
  GH: FlagsListEnum.Ghana,
  GI: FlagsListEnum.Gibraltar,
  GN: FlagsListEnum.Guinea,
  GP: FlagsListEnum.EmptyFlagIcon,
  GM: FlagsListEnum.Gambia,
  GW: FlagsListEnum.GuineaBissau,
  GQ: FlagsListEnum.EquatorialGuinea,
  GR: FlagsListEnum.Greece,
  GD: FlagsListEnum.Grenada,
  GL: FlagsListEnum.Greenland,
  GT: FlagsListEnum.Guatemala,
  GF: FlagsListEnum.EmptyFlagIcon,
  GU: FlagsListEnum.Guam,
  GY: FlagsListEnum.Guyana,
  HK: FlagsListEnum.HongKong,
  HM: FlagsListEnum.EmptyFlagIcon,
  HN: FlagsListEnum.Honduras,
  HR: FlagsListEnum.Croatia,
  HT: FlagsListEnum.Haiti,
  HU: FlagsListEnum.Hungary,
  ID: FlagsListEnum.Indonesia,
  IM: FlagsListEnum.IsleOfMan,
  IN: FlagsListEnum.India,
  IO: FlagsListEnum.BritishIndianOceanTerritory,
  IE: FlagsListEnum.Ireland,
  IR: FlagsListEnum.Iran,
  IQ: FlagsListEnum.Iraq,
  IS: FlagsListEnum.Iceland,
  IL: FlagsListEnum.Israel,
  IT: FlagsListEnum.Italy,
  JM: FlagsListEnum.Jamaica,
  JE: FlagsListEnum.Jersey,
  JO: FlagsListEnum.Jordan,
  JP: FlagsListEnum.Japan,
  KZ: FlagsListEnum.Kazakhstan,
  KE: FlagsListEnum.Kenya,
  KG: FlagsListEnum.Kyrgyzstan,
  KH: FlagsListEnum.Cambodia,
  KI: FlagsListEnum.Kiribati,
  KN: FlagsListEnum.EmptyFlagIcon,
  KR: FlagsListEnum.SouthKorea,
  KW: FlagsListEnum.Kuwait,
  LA: FlagsListEnum.Laos,
  LB: FlagsListEnum.Lebanon,
  LR: FlagsListEnum.Liberia,
  LY: FlagsListEnum.Libya,
  LC: FlagsListEnum.StLucia,
  LI: FlagsListEnum.Liechtenstein,
  LK: FlagsListEnum.SriLanka,
  LS: FlagsListEnum.Lesotho,
  LT: FlagsListEnum.Lithuania,
  LU: FlagsListEnum.Luxembourg,
  LV: FlagsListEnum.Latvia,
  MO: FlagsListEnum.Macao,
  MOP: FlagsListEnum.EmptyFlagIcon,
  MF: FlagsListEnum.EmptyFlagIcon,
  MA: FlagsListEnum.Morocco,
  MC: FlagsListEnum.Monaco,
  MD: FlagsListEnum.Moldova,
  MG: FlagsListEnum.Madagascar,
  MV: FlagsListEnum.Maldives,
  MX: FlagsListEnum.Mexico,
  MH: FlagsListEnum.MarshallIsland,
  MK: FlagsListEnum.RepublicOfMacedonia,
  ML: FlagsListEnum.Mali,
  MT: FlagsListEnum.Malta,
  MM: FlagsListEnum.Myanmar,
  ME: FlagsListEnum.Montenegro,
  MN: FlagsListEnum.Mongolia,
  MP: FlagsListEnum.NorthernMarianasIslands,
  MZ: FlagsListEnum.Mozambique,
  MR: FlagsListEnum.Mauritania,
  MS: FlagsListEnum.Montserrat,
  MQ: FlagsListEnum.Martinique,
  MU: FlagsListEnum.Mauritius,
  MW: FlagsListEnum.Malawi,
  MY: FlagsListEnum.Malaysia,
  YT: FlagsListEnum.EmptyFlagIcon,
  NA: FlagsListEnum.Namibia,
  NC: FlagsListEnum.EmptyFlagIcon,
  NE: FlagsListEnum.Niger,
  NF: FlagsListEnum.NorfolkIsland,
  NG: FlagsListEnum.Nigeria,
  NI: FlagsListEnum.Nicaragua,
  NU: FlagsListEnum.Niue,
  NL: FlagsListEnum.Netherlands,
  NO: FlagsListEnum.Norway,
  NP: FlagsListEnum.Nepal,
  NR: FlagsListEnum.Nauru,
  NZ: FlagsListEnum.NewZealand,
  OM: FlagsListEnum.Oman,
  PK: FlagsListEnum.Pakistan,
  PA: FlagsListEnum.Panama,
  PN: FlagsListEnum.PitcairnIslands,
  PE: FlagsListEnum.Peru,
  PH: FlagsListEnum.Philippines,
  PW: FlagsListEnum.Palau,
  PG: FlagsListEnum.PapuaNewGuinea,
  PL: FlagsListEnum.Poland,
  PR: FlagsListEnum.PuertoRico,
  KP: FlagsListEnum.NorthKorea,
  PT: FlagsListEnum.Portugal,
  PY: FlagsListEnum.Paraguay,
  PS: FlagsListEnum.Palestine,
  PF: FlagsListEnum.FrenchPolynesia,
  QA: FlagsListEnum.Qatar,
  RO: FlagsListEnum.Romania,
  RU: FlagsListEnum.Russia,
  RW: FlagsListEnum.Rwanda,
  SA: FlagsListEnum.SaudiArabia,
  SD: FlagsListEnum.Sudan,
  SN: FlagsListEnum.Senegal,
  SG: FlagsListEnum.Singapore,
  GS: FlagsListEnum.EmptyFlagIcon,
  SH: FlagsListEnum.EmptyFlagIcon,
  SJ: FlagsListEnum.EmptyFlagIcon,
  SB: FlagsListEnum.SolomonIslands,
  SL: FlagsListEnum.SierraLeone,
  SV: FlagsListEnum.ElSalvador,
  SM: FlagsListEnum.SanMarino,
  SO: FlagsListEnum.Somalia,
  PM: FlagsListEnum.EmptyFlagIcon,
  RS: FlagsListEnum.Serbia,
  SS: FlagsListEnum.SouthSudan,
  ST: FlagsListEnum.SaoTomeAndPrince,
  SR: FlagsListEnum.Suriname,
  SK: FlagsListEnum.Slovakia,
  SI: FlagsListEnum.Slovenia,
  SE: FlagsListEnum.Sweden,
  SZ: FlagsListEnum.EmptyFlagIcon,
  SX: FlagsListEnum.SintMaarten,
  SC: FlagsListEnum.Seychelles,
  SY: FlagsListEnum.Syria,
  TC: FlagsListEnum.TurksAndCaicos,
  TD: FlagsListEnum.Chad,
  TG: FlagsListEnum.Togo,
  TH: FlagsListEnum.Thailand,
  TJ: FlagsListEnum.Tajikistan,
  TK: FlagsListEnum.Tokelau,
  TM: FlagsListEnum.Turkmenistan,
  TL: FlagsListEnum.EastTimor,
  TO: FlagsListEnum.Tonga,
  TT: FlagsListEnum.TrinidadAndTobago,
  TN: FlagsListEnum.Tunisia,
  TR: FlagsListEnum.Turkey,
  TV: FlagsListEnum.Tuvalu,
  TW: FlagsListEnum.Taiwan,
  TZ: FlagsListEnum.Tanzania,
  UG: FlagsListEnum.Uganda,
  UA: FlagsListEnum.Ukraine,
  UM: FlagsListEnum.EmptyFlagIcon,
  UY: FlagsListEnum.Uruguay,
  US: FlagsListEnum.UnitedStates,
  UZ: FlagsListEnum.Uzbekistán,
  VA: FlagsListEnum.VaticanCity,
  VC: FlagsListEnum.StVincentAndTheGrenadines,
  VE: FlagsListEnum.Venezuela,
  VG: FlagsListEnum.BritishVirginIslands,
  VI: FlagsListEnum.VirginIslands,
  VN: FlagsListEnum.Vietnam,
  VU: FlagsListEnum.Vanuatu,
  WF: FlagsListEnum.EmptyFlagIcon,
  WS: FlagsListEnum.Samoa,
  YE: FlagsListEnum.Yemen,
  ZA: FlagsListEnum.SouthAfrica,
  ZM: FlagsListEnum.Zambia,
  ZW: FlagsListEnum.Zimbabwe,
  XK: FlagsListEnum.Kosovo,
};

export type FlagsList = keyof typeof FlagsListEnum;

export const LanguagesFlagsInitials: { [key: string]: any } = {
  DE: FlagsListEnum.Germany,
  NL: FlagsListEnum.Netherlands,
  BG: FlagsListEnum.Bulgaria,
  HU: FlagsListEnum.Hungary,
  EL: FlagsListEnum.Greece,
  CS: FlagsListEnum.CzechRepublic,
  DA: FlagsListEnum.Denmark,
  ET: FlagsListEnum.Estonia,
  FI: FlagsListEnum.Finland,
  FR: FlagsListEnum.France,
  HR: FlagsListEnum.Croatia,
  TR: FlagsListEnum.Turkey,
  SR: FlagsListEnum.Suriname,
  EN: FlagsListEnum.UnitedStates,
  IT: FlagsListEnum.Italy,
  LV: FlagsListEnum.Latvia,
  LT: FlagsListEnum.Lithuania,
  BS: FlagsListEnum.BosniaAndHerzegovina,
  MT: FlagsListEnum.Malta,
  CA: FlagsListEnum.EmptyFlagIcon,
  PL: FlagsListEnum.Poland,
  PT: FlagsListEnum.Portugal,
  RO: FlagsListEnum.Romania,
  SK: FlagsListEnum.Slovakia,
  SL: FlagsListEnum.Slovenia,
  ES: FlagsListEnum.Spain,
  SV: FlagsListEnum.Sweden,
  NO: FlagsListEnum.Norway,
  ZH: FlagsListEnum.China,
  JA: FlagsListEnum.Japan,
  TH: FlagsListEnum.Thailand,
  BN: FlagsListEnum.Bangladesh,
  HI: FlagsListEnum.EmptyFlagIcon,
  KO: FlagsListEnum.NorthKorea,
  VI: FlagsListEnum.Vietnam,
  TE: FlagsListEnum.EmptyFlagIcon,
  RU: FlagsListEnum.Russia,
  BE: FlagsListEnum.Belarus,
  SQ: FlagsListEnum.Albania,
  UK: FlagsListEnum.Ukraine,
  IS: FlagsListEnum.Iceland,
  MS: FlagsListEnum.Malaysia,
  ID: FlagsListEnum.Indonesia,
  TL: FlagsListEnum.Philippines,
  AR: FlagsListEnum.SaudiArabia,
  AF: FlagsListEnum.SouthAfrica,
  SW: FlagsListEnum.EmptyFlagIcon,
  EO: FlagsListEnum.EmptyFlagIcon,
};
