import { Navigate, Outlet } from 'react-router-dom';

interface ICheckRole {
  currentRole?: string;
  roleToCheck: string;
}

const CheckRole = ({ currentRole, roleToCheck }: ICheckRole) => {
  if (currentRole && currentRole === roleToCheck) {
    return <Outlet />;
  }

  if (currentRole && currentRole !== roleToCheck) {
    return <Navigate to={'/'} />;
  }

  return <Outlet />;
};

export default CheckRole;
