import { IIconProps } from '../interface';

const SvgSweden = ({ width = 56, height = 56, type }: IIconProps) => {
  switch (type) {
    case 'circle':
      console.warn('Invalid option for this icon');
      return null;
    case 'md':
      console.warn('Invalid option for this icon');
      return null;
    case 'sm':
      console.warn('Invalid option for this icon');
      return null;
    case 'filled':
      console.warn('Invalid option for this icon');
      return null;
    case 'stripe':
      console.warn('Invalid option for this icon');
      return (
        <svg
          width={width}
          height={height}
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 16 16"
        >
          <g fill="none">
            <path
              fill="#217AB7"
              d="M2 2h12a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V4a2 2 0 0 1 2-2Z"
            ></path>
            <path fill="#FCD669" d="M16 9H7v5H4V9H0V6h4V2h3v4h9z"></path>
            <path
              fill="#A68527"
              d="M15 9V6h1v3h-1ZM4 13h3v1H4v-1ZM4 2h3v1H4V2ZM0 6h1v3H0V6Z"
              opacity="0.1"
            ></path>
          </g>
        </svg>
      );
    default:
      return (
        <svg
          width={width}
          height={height}
          viewBox="0 0 56 56"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M28 56C43.464 56 56 43.464 56 28C56 12.536 43.464 0 28 0C12.536 0 0 12.536 0 28C0 43.464 12.536 56 28 56Z"
            fill="#FFDA44"
          />
          <path
            d="M21.9132 24.3479H55.763C53.9733 10.6096 42.2261 0 28 0C25.9094 0 23.873 0.231109 21.9131 0.665547V24.3479H21.9132Z"
            fill="#0052B4"
          />
          <path
            d="M14.6087 24.3477V3.40451C6.92031 7.59948 1.41645 15.2941 0.237061 24.3479H14.6087V24.3477Z"
            fill="#0052B4"
          />
          <path
            d="M14.6086 31.6521H0.237061C1.41645 40.7059 6.92031 48.4005 14.6087 52.5954L14.6086 31.6521Z"
            fill="#0052B4"
          />
          <path
            d="M21.9131 31.6522V55.3344C23.873 55.7689 25.9094 56 28 56C42.2261 56 53.9733 45.3904 55.763 31.6521H21.9131V31.6522Z"
            fill="#0052B4"
          />
        </svg>
      );
  }
};

export default SvgSweden;
