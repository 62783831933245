import { HTMLProps } from 'react';
import './TooltipToItem.css';

export enum EPosition {
  TOP = 'TOP',
  BOTTOM = 'BOTTOM',
  RIGHT = 'RIGHT',
  LEFT = 'LEFT',
}

interface ITooltipToItem {
  text: string;
  textCenter?: boolean;
  position: EPosition;
  active: boolean;
  nowrap?: boolean;
  spacepre?: boolean;
  className?: HTMLProps<HTMLElement>['className'];
  classNameToArrow?: HTMLProps<HTMLElement>['className'];
}

const TooltipToItem = ({
  text,
  textCenter,
  position,
  active,
  nowrap,
  className,
  classNameToArrow,
  spacepre,
}: ITooltipToItem) => {
  const positionStyle = () => {
    if (EPosition.TOP === position) {
      return 'bottom-full mb-spacing-12';
    }

    if (EPosition.BOTTOM === position) {
      return 'top-full mt-spacing-12';
    }

    if (EPosition.RIGHT === position) {
      return 'left-full ml-spacing-12';
    }

    if (EPosition.LEFT === position) {
      return 'right-full mr-spacing-12';
    }
  };

  const renderArrow = () => {
    if (EPosition.TOP === position) {
      return <div className={`${classNameToArrow} arrow-down`}></div>;
    }

    if (EPosition.BOTTOM === position) {
      return <div className={`${classNameToArrow} arrow-up`}></div>;
    }

    if (EPosition.RIGHT === position) {
      return <div className={`${classNameToArrow} arrow-left`}></div>;
    }

    if (EPosition.LEFT === position) {
      return <div className={`${classNameToArrow} arrow-right`}></div>;
    }
  };

  return (
    <div
      className={`absolute transition-all ${positionStyle()} ${
        active ? 'visible' : 'invisible'
      } ${className}`}
    >
      <div
        className={`flex justify-center rounded-4sm transition-all bg-base-platinum700 text-base-white p-spacing-10 whitespace-pre-line ${
          active ? 'opacity-100' : 'opacity-0'
        } ${nowrap && '!whitespace-nowrap'} ${spacepre && '!whitespace-pre'} ${
          textCenter && 'text-center'
        }`}
      >
        {text}
        {renderArrow()}
      </div>
    </div>
  );
};

export default TooltipToItem;
