import { IIconProps } from '../interface';

const SvgAdvice = ({ width = 32, height = 32, color, type }: IIconProps) => {
  switch (type) {
    case 'circle':
      console.warn('Invalid option for this icon');
      return null;
    case 'md':
      console.warn('Invalid option for this icon');
      return null;
    case 'sm':
      console.warn('Invalid option for this icon');
      return null;
    case 'filled':
      console.warn('Invalid option for this icon');
      return null;
    default:
      return (
        <svg
          width={width}
          height={height}
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g clipPath="url(#clip0_4431_35241)">
            <g clipPath="url(#clip1_4431_35241)">
              <path
                d="M12 13V10"
                stroke={color}
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M12 5V3"
                stroke={color}
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M6.5 5H17.185C17.474 5 17.748 5.125 17.938 5.342L19.255 6.851C19.58 7.223 19.58 7.777 19.255 8.149L17.938 9.658C17.748 9.875 17.474 10 17.185 10H6.5C5.948 10 5.5 9.552 5.5 9V6C5.5 5.448 5.948 5 6.5 5Z"
                stroke={color}
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M17.499 18H6.81399C6.52499 18 6.25099 17.875 6.06099 17.658L4.74399 16.149C4.41899 15.777 4.41899 15.223 4.74399 14.851L6.06099 13.342C6.24999 13.125 6.52499 13 6.81399 13H17.499C18.051 13 18.499 13.448 18.499 14V17C18.499 17.552 18.051 18 17.499 18Z"
                stroke={color}
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M12 21V18"
                stroke={color}
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </g>
          </g>
          <defs>
            <clipPath id="clip0_4431_35241">
              <rect width="24" height="24" fill="white" />
            </clipPath>
            <clipPath id="clip1_4431_35241">
              <rect width="24" height="24" fill="white" />
            </clipPath>
          </defs>
        </svg>
      );
  }
};

export default SvgAdvice;
