import { IIconProps } from '../interface';

const SvgClock = ({
  width = 24,
  height = 24,
  color = '#323232',
  type,
}: IIconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_4628_67920)">
        <path
          d="M8.55691 3.68702C13.1479 1.78502 18.4109 3.96602 20.3129 8.55702C22.2149 13.148 20.0339 18.411 15.4429 20.313C10.8519 22.215 5.58891 20.034 3.68691 15.443C1.78591 10.852 3.96591 5.58902 8.55691 3.68702Z"
          stroke={color}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M11.7178 7.98505V12.636L15.3738 14.865"
          stroke={color}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_4628_67920">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default SvgClock;
