import { IIconProps } from '../interface';

const SvgMyIconsBookUserCopy = ({
  width = 24,
  height = 24,
  color = '#323232',
}: IIconProps) => {
  return (
    <svg
      width={width}
      height={height}
      id="Layer_3"
      data-name="Layer 3"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
    >
      <path
        d="M19,8V5a2,2,0,0,0-2-2H5A2,2,0,0,0,3,5V19"
        fill="none"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
      />
      <path
        d="M7,3V17"
        fill="none"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
      />
      <path
        d="M11,17H5a2,2,0,0,0-2,2H3a2,2,0,0,0,2,2h5"
        fill="none"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
      />
      <path
        d="M21,20.498v-.5a2,2,0,0,0-2-2H15.5a2,2,0,0,0-2,2v.5a.5.5,0,0,0,.5.5h6.5A.5.5,0,0,0,21,20.498Z"
        fill="none"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
      />
      <circle
        cx="17.25003"
        cy="13.50001"
        r="2"
        fill="none"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
      />
      <rect y="0.00001" width="24" height="24" fill="none" />
    </svg>
  );
};

export default SvgMyIconsBookUserCopy;
