import { IIconProps } from '../interface';

const SvgSlovenia = ({ width = 56, height = 56, type }: IIconProps) => {
  switch (type) {
    case 'circle':
      console.warn('Invalid option for this icon');
      return null;
    case 'md':
      console.warn('Invalid option for this icon');
      return null;
    case 'sm':
      console.warn('Invalid option for this icon');
      return null;
    case 'filled':
      console.warn('Invalid option for this icon');
      return null;
    case 'stripe':
      console.warn('Invalid option for this icon');
      return (
        <svg
          width={width}
          height={height}
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 16 16"
        >
          <g fill="none" fill-rule="evenodd">
            <path
              fill="#F6F9FC"
              d="M1.994 2A1.997 1.997 0 0 0 0 4.005V7h16V4.005A2 2 0 0 0 14.006 2H1.994Z"
            ></path>
            <path
              fill="#EAEEF3"
              fill-rule="nonzero"
              d="M15 6V4.005A1 1 0 0 0 14.006 3H1.994A.997.997 0 0 0 1 4.005V6H0V4.005C0 2.894.893 2 1.994 2h12.012A2 2 0 0 1 16 4.005V6h-1Z"
            ></path>
            <path fill="#217AB7" d="M0 6h16v4H0z"></path>
            <path
              fill="#E25950"
              d="M0 10v2c0 1.105.895 2 1.994 2h12.012A1.992 1.992 0 0 0 16 12v-2H0Z"
            ></path>
            <path
              fill="#FFF"
              fill-rule="nonzero"
              d="M3 4h4v2.5a2 2 0 1 1-4 0V4Z"
            ></path>
            <path fill="#217AB7" d="M3 4h4v2H3z"></path>
          </g>
        </svg>
      );
    default:
      return (
        <svg
          width={width}
          height={height}
          viewBox="0 0 56 56"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g clipPath="url(#clip0_172_16611)">
            <path
              d="M28 56C43.464 56 56 43.464 56 28C56 12.536 43.464 0 28 0C12.536 0 0 12.536 0 28C0 43.464 12.536 56 28 56Z"
              fill="#F0F0F0"
            />
            <path
              d="M54.2584 18.2609H24.3479V10.9565H9.73908V18.2609H1.74158C0.616109 21.2941 0 24.575 0 28C0 31.425 0.616109 34.7058 1.74158 37.7391L28 40.1739L54.2584 37.7391C55.3839 34.7058 56 31.425 56 28C56 24.575 55.3839 21.2941 54.2584 18.2609Z"
              fill="#0052B4"
            />
            <path
              d="M28 56C40.039 56 50.3022 48.4015 54.2584 37.7391H1.74158C5.69778 48.4015 15.961 56 28 56Z"
              fill="#D80027"
            />
            <path
              d="M9.73914 18.2609V20.6956C9.73914 26.287 17.0435 28.0001 17.0435 28.0001C17.0435 28.0001 24.3479 26.287 24.3479 20.6956V18.2609L21.9131 20.6957L17.0435 17.0436L12.1739 20.6957L9.73914 18.2609Z"
              fill="#F0F0F0"
            />
          </g>
          <defs>
            <clipPath id="clip0_172_16611">
              <rect width="56" height="56" fill="white" />
            </clipPath>
          </defs>
        </svg>
      );
  }
};

export default SvgSlovenia;
