import { IIconProps } from '../interface';

const SvgMyIconsBank6 = ({
  width = 24,
  height = 24,
  color = '#323232',
}: IIconProps) => {
  return (
    <svg
      width={width}
      height={height}
      version="1.1"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <defs>
        <path d="M5,10v8" id="c"></path>
        <path d="M19,18v-8" id="b"></path>
        <path d="M14.643,10v8" id="a"></path>
        <path d="M9.357,18v-8" id="d"></path>
      </defs>
      <g
        strokeLinecap="round"
        strokeWidth="1.5"
        stroke={color}
        fill="none"
        strokeLinejoin="round"
      >
        <path d="M21,10h-18v-3.006l9.152,-3.994l8.848,3.861Z"></path>
        <use xlinkHref="#a"></use>
        <use xlinkHref="#b"></use>
        <use xlinkHref="#a"></use>
        <use xlinkHref="#b"></use>
        <use xlinkHref="#c"></use>
        <use xlinkHref="#d"></use>
        <use xlinkHref="#c"></use>
        <use xlinkHref="#d"></use>
        <path d="M3,21h18v-1l-1,-2h-16l-1,2Z"></path>
      </g>
      <path fill="none" d="M24,0v24h-24v-24h24Z"></path>
    </svg>
  );
};

export default SvgMyIconsBank6;
