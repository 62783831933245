import { IIconProps } from '../interface';

const SvgLatvia = ({ width = 56, height = 56, type }: IIconProps) => {
  if (type === 'stripe') {
    return (
      <svg
        width={width}
        height={height}
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 16 16"
      >
        <g fill="none" fill-rule="evenodd">
          <path
            fill="#B1372F"
            d="M1.994 2h12.012A2 2 0 0 1 16 4.005V7H0V4.005C0 2.894.893 2 1.994 2ZM0 9h16v2.995A1.997 1.997 0 0 1 14.006 14H1.994A2 2 0 0 1 0 11.995V9Z"
          ></path>
          <path fill="#F6F9FC" d="M0 7h16v2H0z"></path>
          <path
            fill="#EAEEF3"
            fill-rule="nonzero"
            d="M14.933 9V7H16v2h-1.067ZM0 7h1.067v2H0V7Z"
          ></path>
        </g>
      </svg>
    );
  }

  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 56 57"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_172_15983)">
        <path
          d="M28 56.8242C43.464 56.8242 56 44.2882 56 28.8242C56 13.3602 43.464 0.824219 28 0.824219C12.536 0.824219 0 13.3602 0 28.8242C0 44.2882 12.536 56.8242 28 56.8242Z"
          fill="#F0F0F0"
        />
        <path
          d="M28 0.824219C15.0633 0.824219 4.1776 9.59839 0.9646 21.5198H55.0356C51.8224 9.59839 40.9368 0.824219 28 0.824219Z"
          fill="#A2001D"
        />
        <path
          d="M28 56.8242C40.9368 56.8242 51.8224 48.05 55.0354 36.1286H0.9646C4.1776 48.0499 15.0633 56.8242 28 56.8242Z"
          fill="#A2001D"
        />
      </g>
      <defs>
        <clipPath id="clip0_172_15983">
          <rect
            width="56"
            height="57"
            fill="white"
            transform="translate(0 0.824219)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default SvgLatvia;
