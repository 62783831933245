import { IIconProps } from '../interface';

const SvgCyprus = ({ width = 56, height = 56, type }: IIconProps) => {
  if (type === 'stripe') {
    return (
      <svg
        width={width}
        height={height}
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 16 16"
      >
        <g fill="none" fill-rule="evenodd">
          <path
            fill="#F6F9FC"
            fill-rule="nonzero"
            d="M2 2h12a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V4a2 2 0 0 1 2-2Z"
          ></path>
          <path
            fill="#24B47E"
            fill-rule="nonzero"
            d="M6.5 9a1.5 1.5 0 0 0 3 0h1A2.498 2.498 0 0 1 8 11.5 2.5 2.5 0 0 1 5.5 9h1Z"
          ></path>
          <path fill="#F79A59" d="m6 6 4-1v2L6 8z"></path>
          <path
            fill="#EAEEF3"
            fill-rule="nonzero"
            d="M14 14H2a2 2 0 0 1-2-2V4a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2Zm1-10a1 1 0 0 0-1-1H2a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V4Z"
          ></path>
        </g>
      </svg>
    );
  }

  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 56 56"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M28 56C43.464 56 56 43.464 56 28C56 12.536 43.464 0 28 0C12.536 0 0 12.536 0 28C0 43.464 12.536 56 28 56Z"
        fill="#FCFCFC"
      />
      <path
        d="M43.8261 24.3479C42.974 24.3479 41.9188 24.3479 40.174 24.3479C40.174 31.0714 34.7234 36.5217 28.0001 36.5217C21.2765 36.5217 15.8262 31.0714 15.8262 24.3479C14.6493 24.3479 13.3507 24.3479 12.1741 24.3479C12.1741 31.5901 17.0386 37.6962 23.6788 39.5767C22.9508 40.9596 23.0617 42.7008 24.1016 43.9985C25.4675 42.9038 26.8816 41.7705 28.0812 40.8093C29.2809 41.7706 30.6951 42.9039 32.0609 43.9985C33.1111 42.6879 33.2144 40.9246 32.4623 39.535C39.0297 37.6084 43.8261 31.5386 43.8261 24.3479Z"
        fill="#6DA544"
      />
      <path
        d="M18.2609 23.1304C18.2609 23.1304 18.2609 29.2173 24.3478 29.2173L25.5651 30.4347H27.9999C27.9999 30.4347 29.2173 26.7825 31.6521 26.7825C31.6521 26.7825 31.6521 24.3478 34.0869 24.3478C36.5217 24.3478 37.739 24.3478 37.739 24.3478C37.739 24.3478 36.5217 19.4782 42.6086 15.826L40.1738 14.6087C40.1738 14.6087 31.6521 20.6956 25.5651 19.4783V21.9131H23.1303L21.913 20.6957L18.2609 23.1304Z"
        fill="#FFDA44"
      />
    </svg>
  );
};

export default SvgCyprus;
