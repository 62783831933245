import { IIconProps } from '../interface';

const SvgFinland = ({ width = 56, height = 56, type }: IIconProps) => {
  if (type === 'stripe') {
    return (
      <svg
        width={width}
        height={height}
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 16 16"
      >
        <g fill="none">
          <path
            fill="#F6F9FC"
            d="M2 2h12a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V4a2 2 0 0 1 2-2Z"
          ></path>
          <path
            fill="#EAEEF3"
            d="M14 14H2a2 2 0 0 1-2-2V4a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2Zm1-10a1 1 0 0 0-1-1H2a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V4Z"
          ></path>
          <path fill="#43458B" d="M16 9H7v5H4V9H0V6h4V2h3v4h9z"></path>
        </g>
      </svg>
    );
  }

  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 56 56"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_172_15683)">
        <path
          d="M28 56C43.464 56 56 43.464 56 28C56 12.536 43.464 0 28 0C12.536 0 0 12.536 0 28C0 43.464 12.536 56 28 56Z"
          fill="#F0F0F0"
        />
        <path
          d="M55.763 24.3479H21.9132H21.9131V0.665558C19.3255 1.23934 16.8723 2.16935 14.6087 3.40453V24.3477V24.3478H0.237016C0.0811563 25.5433 0 26.7622 0 28C0 29.2378 0.0811563 30.4567 0.237016 31.6522H14.6086H14.6087V52.5954C16.8723 53.8305 19.3255 54.7607 21.9131 55.3344V31.6525V31.6523H55.763C55.9186 30.4567 56 29.2378 56 28C56 26.7622 55.9186 25.5433 55.763 24.3479Z"
          fill="#0052B4"
        />
      </g>
      <defs>
        <clipPath id="clip0_172_15683">
          <rect width="56" height="56" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default SvgFinland;
