import { IIconProps } from '../interface';

const SvgAustria = ({ width = 56, height = 56, type }: IIconProps) => {
  if (type === 'stripe') {
    return (
      <svg
        width={width}
        height={height}
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 16 16"
      >
        <g fill="none" fill-rule="evenodd">
          <path
            fill="#E25950"
            d="M1.994 2h12.012C15.106 2 16 2.895 16 4v2H0V4c0-1.112.893-2 1.994-2ZM0 10h16v2c0 1.112-.893 2-1.994 2H1.994C.894 14 0 13.105 0 12v-2Z"
          ></path>
          <path fill="#F6F9FC" d="M0 6h16v4H0z"></path>
          <path
            fill="#EAEEF3"
            fill-rule="nonzero"
            d="M15 10V6h1v4h-1ZM0 6h1v4H0V6Z"
          ></path>
        </g>
      </svg>
    );
  }

  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 56 56"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M54.2584 37.7391C55.384 34.7059 56 31.425 56 28C56 24.575 55.384 21.2943 54.2584 18.2609L28 15.8261L1.74158 18.2609C0.616109 21.2943 0 24.575 0 28C0 31.425 0.616109 34.7059 1.74158 37.7391L28 40.1739L54.2584 37.7391Z"
        fill="#F0F0F0"
      />
      <path
        d="M28 56C40.0389 56 50.3022 48.4015 54.2583 37.7391H1.74146C5.69777 48.4015 15.9609 56 28 56Z"
        fill="#D80027"
      />
      <path
        d="M28 0C15.9609 0 5.69777 7.5985 1.74146 18.2609H54.2584C50.3022 7.5985 40.0389 0 28 0Z"
        fill="#D80027"
      />
    </svg>
  );
};

export default SvgAustria;
