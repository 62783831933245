import { IIconProps } from '../interface';

const SvgMyIconsHome = ({
  width = 24,
  height = 24,
  color = '#323232',
}: IIconProps) => {
  return (
    <svg
      width={width}
      height={height}
      version="1.1"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g fill="none">
        <path d="M0 0h24v24h-24Z"></path>
        <path
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5"
          d="M4 8.6v12.4h16v-12.4"
        ></path>
        <path
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5"
          d="M-32-344l10-7 10 7"
          transform="matrix(1 0 0 1 34 354)"
        ></path>
        <path
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5"
          d="M15 21v-6c0-1.105-.895-2-2-2h-2c-1.105 0-2 .895-2 2v6"
        ></path>
      </g>
    </svg>
  );
};

export default SvgMyIconsHome;
