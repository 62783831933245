import { IIconProps } from '../interface';

const SvgMyIconsAirplanePlaneTripTravel = ({
  width = 24,
  height = 24,
  color = '#323232',
}: IIconProps) => {
  return (
    <svg
      width={width}
      height={height}
      version="1.1"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.081,7.116l3.312,-3.312c0.774,-0.774 2.029,-0.774 2.803,0v0c0.774,0.774 0.774,2.029 0,2.803c0,0 -3.312,3.312 -3.312,3.312l1.792,8.917c0.066,0.328 -0.037,0.667 -0.273,0.904l-0.66,0.66c-0.51,0.51 -1.377,0.328 -1.639,-0.344l-2.624,-6.733l-3.406,3.406l0.182,2.17c0.025,0.293 -0.081,0.583 -0.289,0.791l-1.086,1.086l-2.121,-3.536l-3.536,-2.121l1.086,-1.086c0.208,-0.208 0.497,-0.314 0.791,-0.289l2.17,0.182l3.406,-3.406l-6.732,-2.624c-0.673,-0.262 -0.855,-1.128 -0.345,-1.639l0.66,-0.66c0.237,-0.237 0.576,-0.339 0.904,-0.273c0,0 8.917,1.792 8.917,1.792Z"
        strokeLinecap="round"
        strokeWidth="1.5"
        stroke={color}
        fill="none"
        strokeLinejoin="round"
      ></path>
      <path fill="none" d="M0,0h24v24h-24Z"></path>
    </svg>
  );
};

export default SvgMyIconsAirplanePlaneTripTravel;
