import { IIconProps } from '../interface';

const SvgLuxembourg = ({ width = 56, height = 56, type }: IIconProps) => {
  if (type === 'stripe') {
    return (
      <svg
        width={width}
        height={height}
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 16 16"
      >
        <g fill="none">
          <path fill="#F6F9FC" d="M0 6h16v4H0z"></path>
          <path
            fill="#EAEEF3"
            d="M14.933 10V6H16v4h-1.067ZM0 6h1.067v4H0V6Z"
          ></path>
          <path
            fill="#45B2E8"
            d="M0 12.4V10h16v2.4c0 .884-.682 1.6-1.524 1.6H1.524C.682 14 0 13.284 0 12.4Z"
          ></path>
          <path
            fill="#E25950"
            d="M16 3.6V6H0V3.6C0 2.716.682 2 1.524 2h12.952C15.318 2 16 2.716 16 3.6Z"
          ></path>
        </g>
      </svg>
    );
  }

  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 56 56"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M28 56C43.464 56 56 43.464 56 28C56 12.536 43.464 0 28 0C12.536 0 0 12.536 0 28C0 43.464 12.536 56 28 56Z"
        fill="#F0F0F0"
      />
      <path
        d="M28.0001 0C15.9611 0 5.6979 7.5985 1.7417 18.2609H54.2586C50.3023 7.5985 40.0391 0 28.0001 0Z"
        fill="#D80027"
      />
      <path
        d="M28.0001 56C40.0391 56 50.3023 48.4015 54.2585 37.7391H1.7417C5.6979 48.4015 15.9611 56 28.0001 56Z"
        fill="#338AF3"
      />
    </svg>
  );
};

export default SvgLuxembourg;
