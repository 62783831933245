import { IIconProps } from '../interface';

const SvgMyanmar = ({ width = 56, height = 56, type }: IIconProps) => {
  if (type === 'stripe') {
    return (
      <svg
        width={width}
        height={height}
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 16 16"
      >
        <g fill="none" fill-rule="evenodd">
          <path
            fill="#E25950"
            fill-rule="nonzero"
            d="M14 14H2a2 2 0 0 1-2-2V4a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2Z"
          ></path>
          <path
            fill="#F6F9FC"
            fill-rule="nonzero"
            d="M0 11h16v1H0v-1Zm14 3H2a2 2 0 0 1-1.732-1h15.464A2 2 0 0 1 14 14ZM0 9h16v1H0V9Zm8-2h8v1H8V7Zm0-2h8v1H8V5Zm0-2h7.723c.171.295.277.634.277 1H8V3Z"
          ></path>
          <path
            fill="#E25950"
            fill-rule="nonzero"
            d="M14 14H2a2 2 0 0 1-2-2V8h1v4a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V4a1 1 0 0 0-1-1H8V2h6a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2Z"
            opacity="0.1"
          ></path>
          <path
            fill="#43458B"
            fill-rule="nonzero"
            d="M0 8V3.714C0 2.768.796 2 1.778 2H8v6H0Z"
          ></path>
          <path
            fill="#FCD669"
            d="M4 3.268a1.75 1.75 0 1 0 0 3.464 2 2 0 1 1 0-3.464ZM6 6a1 1 0 1 1 0-2 1 1 0 0 1 0 2Z"
          ></path>
        </g>
      </svg>
    );
  }

  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 56 56"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M54.2584 37.7391C55.384 34.7059 56 31.425 56 28C56 24.575 55.384 21.2941 54.2584 18.2609L28 15.8261L1.74158 18.2609C0.616109 21.2941 0 24.575 0 28C0 31.425 0.616109 34.7059 1.74158 37.7391L28 40.1739L54.2584 37.7391Z"
        fill="#6DA544"
      />
      <path
        d="M54.2585 18.2609C50.3023 7.5985 40.0391 0 28.0001 0C15.9611 0 5.6979 7.5985 1.7417 18.2609H54.2585Z"
        fill="#FFDA44"
      />
      <path
        d="M28.0001 56C40.0391 56 50.3023 48.4015 54.2585 37.7391H1.7417C5.6979 48.4015 15.9611 56 28.0001 56Z"
        fill="#D80027"
      />
      <path
        d="M47.2007 23.6891H32.5327L28 9.73907L23.4673 23.6891H8.79932L20.666 32.3107L16.1332 46.2609L28 37.7391L39.8667 46.2608L35.3341 32.3106L47.2007 23.6891Z"
        fill="#F0F0F0"
      />
    </svg>
  );
};

export default SvgMyanmar;
