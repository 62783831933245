import React from 'react';

type TSpinnerLoadingType = {
  variant: 'primary' | 'secondary' | 'tertiary' | 'reject';
  active: boolean;
  width: number;
  height: number;
};

const SpinnerLoading = ({
  variant,
  active,
  width,
  height,
}: TSpinnerLoadingType) => {
  if (active) {
    switch (variant) {
      case 'primary':
        return (
          <svg
            width={width}
            height={height}
            viewBox="0 0 17 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className="animate-spin"
          >
            <circle
              opacity="0.24"
              cx="8.25"
              cy="8"
              r="7"
              stroke="white"
              strokeWidth="2"
            />
            <path
              d="M1.25 8C1.25 4.13401 4.38401 1 8.25 1C10.1573 1 11.8864 1.76281 13.149 3"
              stroke="white"
              strokeWidth="2"
            />
          </svg>
        );
      case 'secondary':
        return (
          <svg
            width={width}
            height={height}
            viewBox="0 0 17 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className="animate-spin"
          >
            <circle
              opacity="0.24"
              cx="8.25"
              cy="8"
              r="7"
              stroke="#0E1FC1"
              strokeWidth="2"
            />
            <path
              d="M1.25 8C1.25 4.13401 4.38401 1 8.25 1C10.1573 1 11.8864 1.76281 13.149 3"
              stroke="#0E1FC1"
              strokeWidth="2"
            />
          </svg>
        );
      case 'tertiary':
        return (
          <svg
            width={width}
            height={height}
            viewBox="0 0 17 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className="animate-spin"
          >
            <circle
              opacity="0.24"
              cx="8.25"
              cy="8"
              r="7"
              stroke="#272B35"
              strokeWidth="2"
            />
            <path
              d="M1.25 8C1.25 4.13401 4.38401 1 8.25 1C10.1573 1 11.8864 1.76281 13.149 3"
              stroke="#272B35"
              strokeWidth="2"
            />
          </svg>
        );
      case 'reject':
        return (
          <svg
            width={width}
            height={height}
            viewBox="0 0 17 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className="animate-spin"
          >
            <circle
              opacity="0.24"
              cx="8.25"
              cy="8"
              r="7"
              stroke="#A72D25"
              strokeWidth="2"
            />
            <path
              d="M1.25 8C1.25 4.13401 4.38401 1 8.25 1C10.1573 1 11.8864 1.76281 13.149 3"
              stroke="#A72D25"
              strokeWidth="2"
            />
          </svg>
        );
      default:
        return (
          <svg
            width={width}
            height={height}
            viewBox="0 0 17 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className="animate-spin"
          >
            <circle
              opacity="0.24"
              cx="8.25"
              cy="8"
              r="7"
              stroke="white"
              strokeWidth="2"
            />
            <path
              d="M1.25 8C1.25 4.13401 4.38401 1 8.25 1C10.1573 1 11.8864 1.76281 13.149 3"
              stroke="white"
              strokeWidth="2"
            />
          </svg>
        );
    }
  } else {
    return null;
  }
};

export default SpinnerLoading;
