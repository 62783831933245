import AxiosClass from '.';
import env from '../env';

class UserService extends AxiosClass {
  async getUserRole() {
    return this.axiosInstance.get(`${env.APP_API}/user/roles`);
  }

  async getUserDetail() {
    return this.axiosInstance.get(`${env.APP_API}/user/details`);
  }

  async registerUser(payload: any) {
    return this.axiosInstance.post(`${env.APP_API}/auth/register`, payload);
  }
}

export default UserService;
