import { IIconProps } from '../interface';

const SvgMyIconsSkyscraperModern = ({
  width = 24,
  height = 24,
  color = '#323232',
}: IIconProps) => {
  return (
    <svg
      width={width}
      height={height}
      version="1.1"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g fill="none">
        <rect width="24" height="24"></rect>
        <path
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5"
          d="M6 5h2l-4.37114e-08 8.88178e-16c.552285-2.41411e-08 1 .447715 1 1v2h-4v-2l1.15463e-14 1.50996e-07c-8.33927e-08-.552285.447715-1 1-1Z"
        ></path>
        <line
          x1="7"
          x2="7"
          y1="3"
          y2="5"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5"
        ></line>
        <path
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5"
          d="M3 21v-12l1.15463e-14 1.50996e-07c-8.33927e-08-.552285.447715-1 1-1h6l-4.37114e-08 1.77636e-15c.552285-2.41411e-08 1 .447715 1 1v3"
        ></path>
        <path
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5"
          d="M14 12v-7l-1.08307e-08 3.00514e-05c.000190749-.552285.448061-.999845 1.00035-.999655 .119742 4.13565e-05.238502.021588.350627.063614l5 1.875 -1.73103e-08-6.48815e-09c.390597.146402.649289.519878.649028.937011v14.124"
        ></path>
        <path
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5"
          d="M8 21v-8l1.06581e-14 1.50996e-07c-8.33927e-08-.552285.447715-1 1-1h6l-4.37114e-08 1.77636e-15c.552285-2.41411e-08 1 .447715 1 1v8"
        ></path>
        <line
          x1="10.5"
          x2="13.5"
          y1="17.7"
          y2="17.7"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5"
        ></line>
        <line
          x1="10.5"
          x2="13.5"
          y1="15"
          y2="15"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5"
        ></line>
        <line
          x1="22"
          x2="2"
          y1="21"
          y2="21"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5"
        ></line>
      </g>
    </svg>
  );
};

export default SvgMyIconsSkyscraperModern;
