import { IIconProps } from '../interface';

const SvgMyIconsIdSquare = ({
  width = 24,
  height = 24,
  color = '#323232',
}: IIconProps) => {
  return (
    <svg
      width={width}
      height={height}
      version="1.1"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g fill="none">
        <path d="M0 0h24v24h-24v-24Z"></path>
        <path
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5"
          d="M8 15v-6"
        ></path>
        <path
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5"
          d="M14.6 15h-3v-6h3c1.105 0 2 .895 2 2v2c0 1.105-.895 2-2 2Z"
        ></path>
        <path
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5"
          d="M18.353 21.353h-12.706c-1.657 0-3-1.343-3-3v-12.706c0-1.657 1.343-3 3-3h12.705c1.657 0 3 1.343 3 3v12.705c.001 1.658-1.342 3.001-2.999 3.001Z"
        ></path>
      </g>
    </svg>
  );
};

export default SvgMyIconsIdSquare;
