import { IIconProps } from '../interface';

const SvgSpain = ({ width = 56, height = 56, type }: IIconProps) => {
  switch (type) {
    case 'circle':
      console.warn('Invalid option for this icon');
      return null;
    case 'md':
      console.warn('Invalid option for this icon');
      return null;
    case 'sm':
      console.warn('Invalid option for this icon');
      return null;
    case 'filled':
      console.warn('Invalid option for this icon');
      return null;
    case 'stripe':
      console.warn('Invalid option for this icon');
      return (
        <svg
          width={width}
          height={height}
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 16 16"
        >
          <g fill="none">
            <path fill="#FCD669" d="M0 5h16v6H0z"></path>
            <path
              fill="#A68527"
              fill-opacity="0.1"
              d="M15 11V5h1v6h-1ZM0 5h1v6H0V5Z"
            ></path>
            <path
              fill="#E25950"
              d="M0 5V4a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v1H0Zm14 9H2a2 2 0 0 1-2-2v-1h16v1a2 2 0 0 1-2 2Z"
            ></path>
            <path fill="#FFF" d="M4 6h2v2H4z"></path>
            <path fill="#F79A59" d="M4 10.5a2 2 0 0 1-2-2V8h2v2.5Z"></path>
            <path
              fill="#E25950"
              d="M4 10.5V8h2v.5a2 2 0 0 1-2 2ZM2 6h2v2H2V6Z"
            ></path>
          </g>
        </svg>
      );
    default:
      return (
        <svg
          width={width}
          height={height}
          viewBox="0 0 56 56"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M0 28C0 31.425 0.616109 34.7058 1.74158 37.7391L28 40.1739L54.2584 37.7391C55.3839 34.7058 56 31.425 56 28C56 24.575 55.3839 21.2942 54.2584 18.2609L28 15.8261L1.74158 18.2609C0.616109 21.2942 0 24.575 0 28H0Z"
            fill="#FFDA44"
          />
          <path
            d="M54.2585 18.2609C50.3023 7.59861 40.0391 0 28.0001 0C15.9611 0 5.6979 7.59861 1.7417 18.2609H54.2585Z"
            fill="#D80027"
          />
          <path
            d="M1.7417 37.7391C5.6979 48.4014 15.9611 56 28.0001 56C40.0391 56 50.3023 48.4014 54.2585 37.7391H1.7417Z"
            fill="#D80027"
          />
        </svg>
      );
  }
};

export default SvgSpain;
