import { combineReducers } from 'redux';
import trtoolReducer from './trtReducer';
import tatoolReducer from './tatReducer';
import headerReducer from './headerReducer';
import adviceReducer from './adviceReducer';
import userReducer from './user/userReducer';

const rootReducer = combineReducers({
  user: userReducer,
  trt: trtoolReducer,
  tat: tatoolReducer,
  header: headerReducer,
  advice: adviceReducer
  // Add other reducers here
});

export default rootReducer;