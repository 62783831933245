import { createClient } from '@supabase/supabase-js';
import env from './env';

const supabaseUrl = env.APP_SUPABASE_URL || '';
const supabaseAnonKey = env.APP_SUPABASE_ANON_KEY || '';

export const supabase = createClient(supabaseUrl, supabaseAnonKey);

export const getUser = async () => {
  const { data: sessionData } = await supabase.auth.getSession();
  const user = sessionData?.session?.user;

  return user;
};

export const getSession = async () => {
  const { data: sessionData } = await supabase.auth.getSession();
  const user = sessionData?.session;

  return user;
};
