import { User } from '@supabase/supabase-js';
import getUrlParameters from './getUrlParameters';

const checkSession = async (currentUser: User | undefined) => {
  const redirectTo = getUrlParameters('redirectTo');

  if (currentUser) {
    if (window.location.pathname.search(/reset_password/gi) > -1) {     
      return;
    }

    if (redirectTo) {
      window.location.href = redirectTo;
      return;
    }

    if (window.location.pathname.search(/consult/gi) > -1) {
      window.location.href = '/consult';
      return;
    }


    window.location.href = '/';
  }
};

export default checkSession;
