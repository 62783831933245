import { IIconProps } from '../interface';

const SvgNetherlands = ({ width = 56, height = 56, type }: IIconProps) => {
  if (type === 'stripe') {
    return (
      <svg
        width={width}
        height={height}
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 16 16"
      >
        <g fill="none" fill-rule="evenodd">
          <path fill="#F6F9FC" fill-rule="nonzero" d="M0 6h16v4H0z"></path>
          <path
            fill="#EAEEF3"
            fill-rule="nonzero"
            d="M15 10V6h1v4h-1ZM0 6h1v4H0V6Z"
          ></path>
          <path
            fill="#E25950"
            d="M16 4v2H0V4a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2Z"
          ></path>
          <path
            fill="#43458B"
            fill-rule="nonzero"
            d="M0 12v-2h16v2a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2Z"
          ></path>
        </g>
      </svg>
    );
  }

  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 56 56"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M28 56C43.464 56 56 43.464 56 28C56 12.536 43.464 0 28 0C12.536 0 0 12.536 0 28C0 43.464 12.536 56 28 56Z"
        fill="#F0F0F0"
      />
      <path
        d="M28 0C15.961 0 5.69778 7.5985 1.74158 18.2609H54.2585C50.3022 7.5985 40.039 0 28 0Z"
        fill="#A2001D"
      />
      <path
        d="M28 56C40.039 56 50.3022 48.4015 54.2584 37.7391H1.74158C5.69778 48.4015 15.961 56 28 56Z"
        fill="#0052B4"
      />
    </svg>
  );
};

export default SvgNetherlands;
