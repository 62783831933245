import { IIconProps } from '../interface';

const SvgInfo = ({ width = 32, height = 32, color, type }: IIconProps) => {
  switch (type) {
    case 'circle':
      return (
        <svg
          width={width}
          height={height}
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path d="M15 23L15 14.0001H17L17 23H15Z" fill={color} />
          <path
            d="M17.5 10.5001C17.5 11.3285 16.8284 12.0001 16 12.0001C15.1715 12.0001 14.5 11.3285 14.5 10.5001C14.5 9.67164 15.1715 9.00007 16 9.00007C16.8284 9.00007 17.5 9.67164 17.5 10.5001Z"
            fill={color}
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M16 2C8.26801 2 2 8.26801 2 16C2 23.732 8.26801 30 16 30C23.732 30 30 23.732 30 16C30 8.26801 23.732 2 16 2ZM4 16C4 9.37258 9.37258 4 16 4C22.6274 4 28 9.37258 28 16C28 22.6274 22.6274 28 16 28C9.37258 28 4 22.6274 4 16Z"
            fill={color}
          />
        </svg>
      );
    case 'md':
      return (
        <svg
          width={width}
          height={height}
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path d="M15 10L15 30H17L17 10H15Z" fill={color} />
          <path
            d="M16 6C17.1046 6 18 5.10457 18 4C18 2.89543 17.1046 2 16 2C14.8954 2 14 2.89543 14 4C14 5.10457 14.8954 6 16 6Z"
            fill={color}
          />
        </svg>
      );
    case 'sm':
      return (
        <svg
          width={width}
          height={height}
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M16 11C16.8284 11 17.5 10.3284 17.5 9.5C17.5 8.67157 16.8284 8 16 8C15.1716 8 14.5 8.67157 14.5 9.5C14.5 10.3284 15.1716 11 16 11Z"
            fill={color}
          />
          <path d="M15 13L15 24H17L17 13L15 13Z" fill={color} />
        </svg>
      );
    case 'filled':
      return (
        <svg
          width={width}
          height={height}
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M16 30C23.732 30 30 23.732 30 16C30 8.26801 23.732 2 16 2C8.26801 2 2 8.26801 2 16C2 23.732 8.26801 30 16 30ZM17.5 10.5C17.5 11.3284 16.8284 12 16 12C15.1715 12 14.5 11.3284 14.5 10.5C14.5 9.67157 15.1715 9 16 9C16.8284 9 17.5 9.67157 17.5 10.5ZM15 23L15 14H17L17 23H15Z"
            fill={color}
          />
        </svg>
      );
    default:
      return (
        <svg
          width={width}
          height={height}
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path d="M15 23L15 14.0001H17L17 23H15Z" fill={color} />
          <path
            d="M17.5 10.5001C17.5 11.3285 16.8284 12.0001 16 12.0001C15.1715 12.0001 14.5 11.3285 14.5 10.5001C14.5 9.67164 15.1715 9.00007 16 9.00007C16.8284 9.00007 17.5 9.67164 17.5 10.5001Z"
            fill={color}
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M16 2C8.26801 2 2 8.26801 2 16C2 23.732 8.26801 30 16 30C23.732 30 30 23.732 30 16C30 8.26801 23.732 2 16 2ZM4 16C4 9.37258 9.37258 4 16 4C22.6274 4 28 9.37258 28 16C28 22.6274 22.6274 28 16 28C9.37258 28 4 22.6274 4 16Z"
            fill={color}
          />
        </svg>
      );
  }
};

export default SvgInfo;
