export enum EStatus {
  to_do = 'to_do',
  up_coming = 'up_coming',
  done = 'done',
  in_progress = 'in_progress',
  skip = 'skip',
  deposit_submitted = 'deposit_submitted',
  under_review = 'under_review',
}

export interface IToolVerification {
  uiStatus: EStatus;
  complexityKey: 'simple' | 'complex' | 'very_complex';
  taxResidenceKnown: boolean;
  countries: any[];
  fiscalYear: string;
  trtId: string;
  chosenCountry?: string;
  chosenCountryYear?: string;
}

export enum COMPLEXITY_TYPE {
  simple = 'simple',
  taxResidenceKnown = 'taxResidenceKnown',
  complex_one = 'complex_one',
  complex_multiple = 'complex_multiple',
  very_complex = 'very_complex',
}

export enum PARAMS_KEY {
  tatid = 'tatid',
  calendaryearid = 'calendaryearid',
  y = 'y',
}
